import React, { useEffect, useState } from "react"
import { Chevron } from "../../../Assets"
import { Collapse } from "@mui/material"
import { NavLink } from "react-router-dom"
import { Notification } from "../../atom/notification"

export const renderIsActive = (active: boolean): string => {
  if (active) {
    return "text-primary"
  } else {
    return "text-secondary"
  }
}

const openClass =
  "flex gap-[16px] items-center pl-[13px] pr-[20px] py-[16px] hover:bg-lightgray3 rounded-xl cursor-pointer mb-2"
const notOpenClass =
  "flex gap-[16px] items-center justify-center  py-[16px] hover:bg-lightgray3 rounded-xl cursor-pointer mb-2"

interface ISidebarMenu {
  open: boolean
  icon: React.ReactNode
  alt: string
  children?: string
  to?: string
  onClick?: () => void
  title?: string
  isActive?: boolean
}

export const SidebarMenu: React.FC<ISidebarMenu> = (props) => {
  const { open, icon, children, to, onClick, isActive } = props

  return to ? (
    <NavLink
      className={open ? openClass : notOpenClass}
      to={to}
      onClick={onClick}
    >
      {icon}
      {open && (
        <p
          className={`text-sm font-semibold ${
            isActive ? "text-primary" : "text-secondary"
          }`}
        >
          {children}
        </p>
      )}
    </NavLink>
  ) : (
    <div className={open ? openClass : notOpenClass} onClick={onClick}>
      {icon}
      {open && (
        <p className="text-sm font-semibold text-secondary">{children}</p>
      )}
    </div>
  )
}

interface ISidebarMenuDropdown {
  open: boolean
  icon: React.ReactNode
  alt: string
  title: string
  children: any
  notification?: number
  collapseActive?: boolean
  onClick: () => void
  isActive?: boolean
}

export const SidebarMenuDropdown: React.FC<ISidebarMenuDropdown> = (props) => {
  const {
    open,
    icon,
    title,
    children,
    notification,
    collapseActive,
    onClick,
    isActive
  } = props

  const [collapse, setCollapse] = useState<boolean>(false)
  useEffect(() => {
    setCollapse(false)
  }, [open])
  return (
    <>
      <div
        onClick={() => {
          setCollapse(!collapse)
          onClick()
        }}
        className={
          open ? openClass + " justify-between" : notOpenClass + " relative"
        }
      >
        <div className="flex gap-[16px] items-center">
          {icon}
          {open && (
            <div className="flex gap-[16px] items-center justify-between w-full">
              <p
                className={`${
                  !collapse && notification ? "text-[12px]" : "text-sm"
                } font-semibold ${
                  isActive ? "text-primary" : "text-secondary"
                }`}
              >
                {title}
              </p>
              {!collapse && notification && (
                <Notification>{notification}</Notification>
              )}
            </div>
          )}
        </div>
        {open && (
          <img
            src={Chevron}
            alt="Chevron"
            className={`w-3 ${!collapse ? "rotate-180" : ""}`}
          />
        )}
        {!open && collapseActive && (
          <div className="fixed w-[250px] p-3 border bg-white left-16 rounded-xl">
            {children}
          </div>
        )}
      </div>
      {open && <Collapse in={collapse}>{children}</Collapse>}
    </>
  )
}

interface IChildMenu {
  children?: any
  to?: string
  onClick?: () => void
  notification?: number
  isActive?: boolean
}

export const ChildMenu: React.FC<IChildMenu> = (props) => {
  const { children, to, onClick, notification, isActive } = props

  return to ? (
    <NavLink
      to={to}
      className="flex gap-[16px] items-center pl-[13px] pr-[20px] py-[16px] hover:bg-lightgray3 rounded-xl cursor-pointer mb-2"
      onClick={onClick}
    >
      <div className="flex gap-[16px] items-center justify-between w-full">
        <p
          className={`text-sm font-semibold ml-8 ${
            isActive ? "text-primary" : "text-secondary"
          }`}
        >
          {children}
        </p>
        {notification && <Notification>{notification}</Notification>}
      </div>
    </NavLink>
  ) : (
    <div
      className="flex gap-[16px] items-center pl-[13px] pr-[20px] py-[16px] hover:bg-lightgray3 rounded-xl cursor-pointer mb-2"
      onClick={onClick}
    >
      <p className="text-sm font-semibold text-secondary ml-8">{children}</p>
    </div>
  )
}
