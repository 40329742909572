import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { API } from "../../utils/constants/url"
import { customFetchBase } from "../interceptor"
import type {
  ICategoryList,
  ICategoryPayload,
  ICategoryPayloadId
} from "./types"

export const categoryApi = createApi({
  reducerPath: "categoryApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    listCategory: builder.query<any, ICategoryList>({
      query: ({ pageIndex, limit, sortBy, direction, search }) => ({
        url: API.CATEGORY.LIST_CATEGORY,
        method: "GET",
        params: {
          pageIndex,
          limit,
          sortBy,
          direction,
          search
        }
      }),
      transformResponse: (res: any, meta, arg) => {
        const data = res.data.map((data: any, i: number) => ({
          ...{
            id: data.id,
            name: data.name,
            code: data.code,
            productQuantity: data.productQuantity
          }
        }))
        res = data
        return res
      }
    }),
    detailCategory: builder.query({
      query: ({ id }) => ({
        url: `${API.CATEGORY.GET_CATEGORY}/${id}`,
        method: "GET"
      }),
      transformResponse: (res: any, meta, arg) => {
        const data = {
          id: res.data.id,
          name: res.data.name,
          permission: res.data.permissions,
          description: res.data.description
        }
        res = data
        return res
      }
    }),
    createCategory: builder.mutation<any, ICategoryPayload>({
      query: (payload) => ({
        url: API.CATEGORY.CREATE_CATEGORY,
        method: "POST",
        body: payload
      })
    }),
    deleteCategory: builder.mutation({
      query: (payload) => ({
        url: `${API.CATEGORY.DELETE_CATEGORY}/${payload}`,
        method: "DELETE"
      })
    }),
    updateCategory: builder.mutation<any, ICategoryPayloadId>({
      query: (payload) => ({
        url: API.CATEGORY.UPDATE_CATEGORY,
        method: "PUT",
        body: payload
      })
    })
  })
})

export const {
  useListCategoryQuery,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useDetailCategoryQuery,
  useUpdateCategoryMutation
} = categoryApi
