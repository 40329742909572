import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { customFetchBase } from "../interceptor"
import { API } from "../../utils/constants/url"
import type {
  IResponse,
  IGetDistributorList,
  IGetDistributorById,
  ICreateDistributor,
  IUpdateDistributor,
  IDeleteDistributor,
  IGetRegion
} from "./types"

export const distributorApi = createApi({
  reducerPath: "distributorApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getDistributorList: builder.query<IResponse, IGetDistributorList>({
      query: ({ pageIndex, limit, sortBy, direction, search }) => ({
        url: API.MULTI_DISTRIBUTOR.LIST_DISTRIBUTOR,
        method: "GET",
        params: {
          pageIndex,
          limit,
          sortBy,
          direction,
          search
        }
      })
    }),
    getDistributorById: builder.query<IResponse, IGetDistributorById>({
      query: ({ id }) => ({
        url: `${API.MULTI_DISTRIBUTOR.DETAIL_DISTRIBUTOR}/${id}`,
        method: "GET"
      })
    }),
    getUMKMType: builder.query<IResponse, any>({
      query: () => ({
        url: API.MULTI_DISTRIBUTOR.GET_UMKM_TYPE,
        method: "GET"
      })
    }),
    getRegion: builder.query<IResponse, IGetRegion>({
      query: ({ limit, search, parentId, level }) => ({
        url: API.MULTI_DISTRIBUTOR.REGION_LIST,
        method: "GET",
        params: {
          limit,
          search,
          parentId,
          level
        }
      })
    }),
    createDistributor: builder.mutation<any, ICreateDistributor>({
      query: (payload) => ({
        url: API.MULTI_DISTRIBUTOR.CREATE_DISTRIBUTOR,
        method: "POST",
        body: payload
      })
    }),
    updateDistributor: builder.mutation<IResponse, IUpdateDistributor>({
      query: (payload) => ({
        url: API.MULTI_DISTRIBUTOR.UPDATE_DISTRIBUTOR,
        method: "PUT",
        body: payload
      })
    }),
    deleteDistributor: builder.mutation<IResponse, IDeleteDistributor>({
      query: ({ id }) => ({
        url: `${API.MULTI_DISTRIBUTOR.DELETE_DISTRIBUTOR}/${id}`,
        method: "DELETE"
      })
    }),
    uploadLogoDistributor: builder.mutation<IResponse, FormData>({
      query: (payload) => ({
        url: API.MULTI_DISTRIBUTOR.UPLOAD_LOGO_DISTRIBUTOR,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        body: payload,
        formData: true
      })
    })
  })
})

export const {
  useGetDistributorListQuery,
  useGetDistributorByIdQuery,
  useGetUMKMTypeQuery,
  useGetRegionQuery,
  useCreateDistributorMutation,
  useUpdateDistributorMutation,
  useDeleteDistributorMutation,
  useUploadLogoDistributorMutation
} = distributorApi
