import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
export interface IUser {
  nama: string
  role: string
  email: string
}
export interface IUsers {
  users: IUser[]
  name: string
  role: string
  email: string
  userId?: string
  openDeleteModal: boolean
  openPasswordModal: boolean
}
const initialState: IUsers = {
  users: [],
  name: "",
  role: "",
  email: "",
  userId: "",
  openDeleteModal: false,
  openPasswordModal: false
}

const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<any>) => {
      state.userId = action.payload
    },
    addUser: (state, action: PayloadAction<IUser>) => {
      state.users.push(action.payload)
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    deleteUser: (state, action: PayloadAction<string>) => {
      const filter = state.users.filter((d) => d.email !== action.payload)
      state.users = filter
    },
    setOpenDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.openDeleteModal = action.payload
    },
    setOpenPasswordModal: (state, action: PayloadAction<boolean>) => {
      state.openPasswordModal = action.payload
    }
  }
})

export const {
  setUserId,
  addUser,
  setName,
  setRole,
  setEmail,
  deleteUser,
  setOpenDeleteModal,
  setOpenPasswordModal
} = usersSlice.actions
export default usersSlice.reducer
