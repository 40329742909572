import type { ReactNode } from "react"
import React from "react"
import { CloseIcon } from "../../../Assets"
import { createPortal } from "react-dom"
const portal = document.querySelector("#portal") as HTMLElement

export interface ModalType {
  children?: ReactNode
  isOpen: boolean
  width?: string
  height?: string
  title?: string
  toggle: () => void
  onBackdropPress?: React.MouseEventHandler<HTMLDivElement>
  isFilter?: boolean
  toggleResetFilter?: () => void
}

const Modal: React.FC<ModalType> = (props) => {
  if (!props.isOpen) return null
  return createPortal(
    <>
      <div
        onClick={props.onBackdropPress}
        className="w-full h-full bg-[rgba(55,65,81,0.6)] fixed top-0 left-0 right-0 bottom-0 z-[200] flex items-center justify-center"
      >
        <div className=" flex justify-center items-center bg-[rgba(88,99,121,0.32)]  fixed inset-0 z-50 outline-none ">
          <div
            className={`bg-white p-6 pt-8 ${
              props.width ? props.width : "relative"
            } ${props.height} rounded-2xl overflow-y-auto`}
          >
            <div className="flex mb-4">
              <p className="w-full font-bold text-xl text-body">
                {props.title}
              </p>
              {!props.isFilter ? (
                <button onClick={props.toggle}>
                  <img
                    src={CloseIcon}
                    alt="icon-close"
                    width={16}
                    height={16}
                  />
                </button>
              ) : (
                <button onClick={props.toggleResetFilter} className="w-[100px]">
                  <p className="text-[14px] text-primary font-semibold">
                    Reset Filter
                  </p>
                </button>
              )}
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </>,
    portal
  )
  //   return (
  //     <div  onClick={props.onBackdropPress}
  //     className="w-full h-full bg-[rgba(55,65,81,0.6)] fixed top-0 left-0 right-0 bottom-0 z-[2000] flex items-center justify-center">
  //       {props.isOpen && (
  //         <div className=" flex justify-center items-center bg-[rgba(88,99,121,0.32)]  fixed inset-0 z-50 outline-none ">
  //           <div
  //             className={`bg-white p-6 ${
  //               props.width ? props.width : "w-[651px]"
  //             } ${
  //               props.height ? props.height : "h-[447px]"
  //             } rounded-2xl overflow-y-auto`}
  //           >
  //             <div className="flex mb-3">
  //               <p className="w-full font-bold text-xl text-body">
  //                 {props.title}
  //               </p>
  //               <button onClick={props.toggle}>
  //                 <img src={IconClose} alt="icon-close" width={16} height={16} />
  //               </button>
  //             </div>
  //             {props.children}
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   )
}
export default Modal
