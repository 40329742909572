/*eslint-disable*/
import React, { forwardRef, useState } from "react"

export const TextInput = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    leftComponent?: React.ReactNode
    rightComponent?: React.ReactNode
    error?: boolean
    errorText?: string
    label?: string
    border?: string
  }
>(
  (
    { leftComponent, rightComponent, error, errorText, label, border, ...rest },
    ref
  ) => {
    const [active, setActive] = useState<boolean>(false)

    return (
      <>
        <div>
          <div className={`relative ${label ? "mb-5" : ""}`}>
            {label && (
              <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-white">
                {label}
              </label>
            )}
            <div
              onFocus={() => {
                setActive(true)
              }}
              onBlur={() => {
                setActive(false)
              }}
              className={`flex flex-row rounded-xl ${
                error
                  ? "border-error border-[1px]"
                  : active
                  ? "border-cyan1 border-[1px]"
                  : "border-transparent border-[1px]"
              } ${rest.disabled ? "" : "bg-lightgray1"} ${rest.className}`}
            >
              {leftComponent && (
                <div className="flex items-center justify-center pl-2 ">
                  {leftComponent}
                </div>
              )}
              <input
                type="text"
                {...rest}
                className={`
              w-full
              p-0
              pl-3
              pr-1
              bg-transparent
              border-transparent
              focus:border-transparent
              focus:ring-0 min-h-[42px]
              rounded-xl
              placeholder:text-gray-300
              text-[14px]
            `}
                ref={ref}
              />
              {rightComponent && (
                <div className="flex items-center justify-center pr-2 ">
                  {rightComponent}
                </div>
              )}
            </div>
            {error ? (
              <p className="text-[12px] text-error mt-1">{errorText}</p>
            ) : null}
          </div>
        </div>
      </>
    )
  }
)

TextInput.displayName = "TextInput"
