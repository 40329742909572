import React, { Suspense } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { Layout } from "../Components/organisms/Layout"
import Loading from "../Components/atom/Loading"
import Roles from "../pages/Roles"
import RolesForm from "../pages/Roles/Form"
import ChangePassword from "../pages/ChangePassword"
import { checkPermission, PERMISSION_LIST } from "../utils/checkPermission"
import NotAuthorize from "../pages/NotAuthorize"
const Users = React.lazy(async () => await import("../pages/Users"))
// const Dashboard = React.lazy(async () => await import("../pages/Dashboard"))
// const KelolaDashboard = React.lazy(
//   async () => await import("../pages/KelolaDashboard")
// )
// const KelolaDashboardForm = React.lazy(
//   async () => await import("../pages/KelolaDashboard/Form")
// )
const AddUser = React.lazy(async () => await import("../pages/Users/Add"))
const DetailUser = React.lazy(async () => await import("../pages/Users/Detail"))
const Profile = React.lazy(async () => await import("../pages/Profile"))
const WaitingApproval = React.lazy(
  async () => await import("../pages/UMKM/WaitingApproval")
)
const WaitingApprovalDetails = React.lazy(
  async () => await import("../pages/UMKM/WaitingApproval/Details")
)
const ListMember = React.lazy(
  async () => await import("../pages/UMKM/ListMember")
)
const ListMemberDetails = React.lazy(
  async () => await import("../pages/UMKM/ListMember/Details")
)
const HistoryApproval = React.lazy(
  async () => await import("../pages/UMKM/ApprovalHistory")
)
const HistoryApprovalDetails = React.lazy(
  async () => await import("../pages/UMKM/ApprovalHistory/Details")
)
const MasterProduct = React.lazy(
  async () => await import("../pages/MasterProduct")
)
const AddProduct = React.lazy(
  async () => await import("../pages/MasterProduct/Add")
)
const EditProduct = React.lazy(
  async () => await import("../pages/MasterProduct/Details")
)
const Category = React.lazy(async () => await import("../pages/Category/index"))
const OrdersTransaction = React.lazy(
  async () => await import("../pages/OrdersAndTransactions/index")
)
const DetailOrdersTransaction = React.lazy(
  async () => await import("../pages/OrdersAndTransactions/Details")
)
const Delivery = React.lazy(
  async () => await import("../pages/OrdersAndTransactions/Delivery")
)
const MasterUnit = React.lazy(async () => await import("../pages/Unit"))
const MultiDistributor = React.lazy(
  async () => await import("../pages/MultiDistributor")
)
const FormMultiDistributor = React.lazy(
  async () => await import("../pages/MultiDistributor/Form")
)
const Location = React.lazy(async () => await import("../pages/Location"))
// const AddLead = React.lazy(async () => await import("../pages/DataLeads/Form"))
// const DetailLead = React.lazy(
//   async () => await import("../pages/DataLeads/Detail")
// )
// const Leads = React.lazy(async () => await import("../pages/DataLeads"))
const AppAuthenticatedPage = ({ token }: { token: any }): any => {
  return (
    <div>
      <Routes>
        <Route element={<ProtectedRoute token={token} />}>
          <Route
            element={
              <Suspense fallback={<Loading />}>
                <Layout />
              </Suspense>
            }
          >
            {/* <Route index path={""} element={<Dashboard />} />
            <Route path="kelola-dashboard">
              <Route index element={<KelolaDashboard />} />
              <Route path="add" element={<KelolaDashboardForm />} />
              <Route path=":id" element={<KelolaDashboardForm />} />
            </Route> */}
            <Route index path={"/profile"} element={<Profile />} />
            <Route
              index
              path={"/location"}
              element={
                checkPermission(PERMISSION_LIST.CONFIG_GEOLOCATION_VIEW) ? (
                  <Location />
                ) : (
                  <NotAuthorize />
                )
              }
            />
            <Route
              index
              path={"/change-password"}
              element={<ChangePassword />}
            />

            <Route path={"/waiting-approval"}>
              <Route
                index
                element={
                  checkPermission(
                    PERMISSION_LIST.UMKM_WAITING_APPROVAL_VIEW
                  ) ? (
                    <WaitingApproval />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
              <Route
                path=":id"
                element={
                  checkPermission(
                    PERMISSION_LIST.UMKM_WAITING_APPROVAL_VIEW
                  ) ? (
                    <WaitingApprovalDetails />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
            </Route>

            <Route path={"/umkm-list-member"}>
              <Route
                index
                element={
                  checkPermission(PERMISSION_LIST.UMKM_MEMBER_VIEW) ? (
                    <ListMember />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
              <Route
                path=":id"
                element={
                  checkPermission(PERMISSION_LIST.UMKM_MEMBER_VIEW) ? (
                    <ListMemberDetails />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
            </Route>

            <Route path={"/approval-history"}>
              <Route
                index
                element={
                  checkPermission(
                    PERMISSION_LIST.UMKM_HISTORY_APPROVAL_VIEW
                  ) ? (
                    <HistoryApproval />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
              <Route
                path=":id"
                element={
                  checkPermission(
                    PERMISSION_LIST.UMKM_HISTORY_APPROVAL_VIEW
                  ) ? (
                    <HistoryApprovalDetails />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
            </Route>

            <Route path={"/master-unit"}>
              <Route
                index
                element={
                  checkPermission(PERMISSION_LIST.PRODUCT_UNIT_VIEW) ? (
                    <MasterUnit />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
            </Route>

            <Route path={"/master-product"}>
              <Route
                index
                element={
                  checkPermission(PERMISSION_LIST.PRODUCT_VIEW) ? (
                    <MasterProduct />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
              <Route
                path="add"
                element={
                  checkPermission(PERMISSION_LIST.PRODUCT_CREATE) ? (
                    <AddProduct />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
              <Route
                path=":id"
                element={
                  checkPermission(PERMISSION_LIST.PRODUCT_VIEW) ? (
                    <EditProduct />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
            </Route>

            <Route path={"/orders-and-transactions"}>
              <Route
                index
                element={
                  checkPermission(PERMISSION_LIST.TRANSACTION_VIEW) ? (
                    <OrdersTransaction />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
              <Route
                path=":id"
                element={
                  checkPermission(PERMISSION_LIST.TRANSACTION_VIEW) ? (
                    <DetailOrdersTransaction />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />

              <Route
                path="/orders-and-transactions/delivery/:id"
                element={
                  checkPermission(PERMISSION_LIST.TRANSACTION_VIEW) ? (
                    <Delivery />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
            </Route>

            <Route path="/">
              <Route
                index
                element={
                  checkPermission(PERMISSION_LIST.WIZ_DISTRIBUTOR_VIEW) ? (
                    <MultiDistributor />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
              <Route
                path="add"
                element={
                  checkPermission(PERMISSION_LIST.WIZ_DISTRIBUTOR_CREATE) ? (
                    <FormMultiDistributor />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
              <Route
                path=":id"
                element={
                  checkPermission(PERMISSION_LIST.WIZ_DISTRIBUTOR_VIEW) ? (
                    <FormMultiDistributor />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
            </Route>

            {/* <Route path={"/data-leads"}>
              <Route
                index
                element={
                  // checkPermission(PERMISSION_LIST.LEADS_VIEW) ? (
                  //   <Leads />
                  // ) : (
                  //   <NotAuthorize />
                  // )
                  <Leads />
                }
              />
              <Route
                path="/data-leads/detail/:id"
                element={
                  checkPermission(PERMISSION_LIST.LEADS_UPDATE) ? (
                    <DetailLead />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
              <Route
                path="/data-leads/edit/:id"
                element={
                  checkPermission(PERMISSION_LIST.LEADS_UPDATE) ? (
                    <AddLead />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
              <Route
                path="add"
                element={
                  checkPermission(PERMISSION_LIST.LEADS_CREATE) ? (
                    <AddLead />
                  ) : (
                    <NotAuthorize />
                  )
                }
              />
            </Route> */}
            <Route path="category">
              <Route index element={<Category />} />
              <Route path="add" element={<AddUser />} />
              <Route path=":id" element={<DetailUser />} />
            </Route>
            <Route path="users">
              <Route index element={<Users />} />
              <Route path="add" element={<AddUser />} />
              <Route path=":id" element={<DetailUser />} />
            </Route>
            <Route path="role">
              <Route
                index
                // element={
                //   checkPermission(PERMISSION_LIST.ROLE_VIEW) ? (
                //     <Roles />
                //   ) : (
                //     <NotAuthorize />
                //   )
                // }
                element={<Roles />}
              />
              <Route
                path="add"
                // element={
                //   checkPermission(PERMISSION_LIST.ROLE_CREATE) ? (
                //     <RolesForm />
                //   ) : (
                //     <NotAuthorize />
                //   )
                // }
                element={<RolesForm />}
              />
              <Route path=":id" element={<RolesForm />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </div>
  )
}
/* eslint-disable */
export const ProtectedRoute = ({
  token,
  redirectPath = "/login"
}: {
  token: any
  redirectPath?: string
}) => {
  if (!token && token === "") {
    return <Navigate to={redirectPath} replace />
  }

  return <Outlet />
}

export default AppAuthenticatedPage
