import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface INavbar {
  title: string
  backUrl?: string
  fullname?: string
}

const initialState: INavbar = {
  title: "",
  backUrl: "",
  fullname: ""
}

const navbarSlice = createSlice({
  name: "navbarSlice",
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload
    },
    setBackUrl: (state, action: PayloadAction<string>) => {
      state.backUrl = action.payload
    },
    setFullname: (state, action: PayloadAction<string>) => {
      state.fullname = action.payload
    }
  }
})

export const { setTitle, setBackUrl, setFullname } = navbarSlice.actions
export default navbarSlice.reducer
