import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { customFileFetchBase } from "../interceptor"
import { API } from "../../utils/constants/url"
import type { IResponse, IGetLogo } from "./types"

export const fileApi = createApi({
  reducerPath: "fileApi",
  baseQuery: customFileFetchBase,
  endpoints: (builder) => ({
    uploadLogoDistributor: builder.mutation<IResponse, FormData>({
      query: (payload) => ({
        url: API.MULTI_DISTRIBUTOR.UPLOAD_LOGO_DISTRIBUTOR,
        method: "POST",
        body: payload,
        formData: true
      })
    }),
    getLogoDistributor: builder.query<IResponse, IGetLogo>({
      query: ({ fileId }) => ({
        url: API.MULTI_DISTRIBUTOR.GET_LOGO_DISTRIBUTOR,
        method: "GET",
        params: { fileId }
      })
    })
  })
})

export const {
  useUploadLogoDistributorMutation,
  useGetLogoDistributorQuery,
  useLazyGetLogoDistributorQuery
} = fileApi
