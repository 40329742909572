import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { basicQuery } from "../interceptor"
import type { ISendEmail, IResetPassword, ICheckToken } from "./types"
import { API } from "../../utils/constants/url"

export const forgotPasswordApi = createApi({
  reducerPath: "forgotPasswordApi",
  baseQuery: basicQuery,
  endpoints: (builder) => ({
    sendEmail: builder.mutation<any, ISendEmail>({
      query: (payload) => ({
        url: API.FORGOT_PASSWORD.SEND_EMAIL,
        method: "POST",
        body: payload
      })
    }),
    resetPassword: builder.mutation<any, IResetPassword>({
      query: (payload) => ({
        url: API.FORGOT_PASSWORD.RESET_PASSWORD,
        method: "POST",
        body: payload
      })
    }),
    checkToken: builder.mutation<any, ICheckToken>({
      query: (payload) => ({
        url: API.FORGOT_PASSWORD.CHECK_TOKEN,
        method: "POST",
        body: payload
      })
    })
  })
})

export const {
  useSendEmailMutation,
  useResetPasswordMutation,
  useCheckTokenMutation
} = forgotPasswordApi
