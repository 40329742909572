import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface IInitialState {
  transactionsState: {
    page: number
    pageSize: number
    sortBy: string
    direction: string
    confirmSearch: string
    filterByStatus: string
    filterByTransactionDate: string
    filterByCompletedDate: string
    filterByTotalItem: string
  }
  transactionId: string
  selectedTransactionId: string[]
}

const initialState: IInitialState = {
  transactionsState: {
    page: 0,
    pageSize: 10,
    sortBy: "id",
    direction: "asc",
    confirmSearch: "",
    filterByStatus: "",
    filterByTransactionDate: "",
    filterByCompletedDate: "",
    filterByTotalItem: ""
  },
  transactionId: "",
  selectedTransactionId: []
}

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.transactionsState.page = action.payload
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.transactionsState.pageSize = action.payload
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.transactionsState.sortBy = action.payload
    },
    setDirection: (state, action: PayloadAction<string>) => {
      state.transactionsState.direction = action.payload
    },
    setConfirmSearch: (state, action: PayloadAction<string>) => {
      state.transactionsState.confirmSearch = action.payload
    },
    setFilterByStatus: (state, action: PayloadAction<string>) => {
      state.transactionsState.filterByStatus = action.payload
    },
    setFilterByTransactionDate: (state, action: PayloadAction<string>) => {
      state.transactionsState.filterByTransactionDate = action.payload
    },
    setFilterByCompletedDate: (state, action: PayloadAction<string>) => {
      state.transactionsState.filterByCompletedDate = action.payload
    },
    setFilterByTotalItem: (state, action: PayloadAction<string>) => {
      state.transactionsState.filterByTotalItem = action.payload
    },
    resetState: (state) => {
      state.transactionsState.sortBy = "id"
      state.transactionsState.direction = "asc"
      state.transactionsState.confirmSearch = ""
      state.transactionsState.filterByStatus = ""
      state.transactionsState.filterByTransactionDate = ""
      state.transactionsState.filterByCompletedDate = ""
      state.transactionsState.filterByTotalItem = ""
    },
    setTransactionId: (state, action: PayloadAction<string>) => {
      state.transactionId = action.payload
    },
    selectTransactionId: (state, action: PayloadAction<string>) => {
      state.selectedTransactionId.push(action.payload)
    },
    unselectTransactionId: (state, action: PayloadAction<string>) => {
      const index = state.selectedTransactionId.indexOf(action.payload)
      state.selectedTransactionId.splice(index, 1)
    },
    resetSelectedTransactions: (state) => {
      state.selectedTransactionId = []
    }
  }
})

export const {
  setPage,
  setPageSize,
  setSortBy,
  setDirection,
  setConfirmSearch,
  setFilterByStatus,
  setFilterByTransactionDate,
  setFilterByCompletedDate,
  setFilterByTotalItem,
  resetState,
  setTransactionId,
  selectTransactionId,
  unselectTransactionId,
  resetSelectedTransactions
} = transactionsSlice.actions
export default transactionsSlice.reducer
