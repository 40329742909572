import React from "react"

const NotAuthorize: React.FC = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center flex-col gap-4">
      <div className="flex justify-center text-center items-center ">
        <h1 className="text-3xl">401 Not Authorize</h1>
      </div>
    </div>
  )
}
export default NotAuthorize
