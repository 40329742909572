
interface IGetCredential {
  accessToken: string
  refreshToken: string
  roles: string[]
}

const getCredential = (): IGetCredential => {
  const accessToken = localStorage.getItem("accessToken") || ""
  const refreshToken = localStorage.getItem("refreshToken") || ""
  const roles = localStorage.getItem("roles")
    ? JSON.parse(localStorage.getItem("roles") || "")
    : []

  return {
    accessToken,
    refreshToken,
    roles
  }
}

export default getCredential
