import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface IUnit {
  openAddModal: boolean
  openEditModal: boolean
  unitCode: string
}
const initialState: IUnit = {
  openAddModal: false,
  openEditModal: false,
  unitCode: ""
}

const unitSlice = createSlice({
  name: "unitSlice",
  initialState,
  reducers: {
    setOpenAddModal: (state, action: PayloadAction<boolean>) => {
      state.openAddModal = action.payload
    },
    setOpenEditModal: (state, action: PayloadAction<boolean>) => {
      state.openEditModal = action.payload
    },
    setUnitCode: (state, action: PayloadAction<string>) => {
      state.unitCode = action.payload
    }
  }
})

export const { setOpenAddModal, setOpenEditModal, setUnitCode } =
  unitSlice.actions
export default unitSlice.reducer
