import { useEffect, useState, useMemo } from "react"
export const useDetectBottom = (ref: any): { isVisible: boolean } => {
  const [isVisible, setIsVisible] = useState(false)

  const observer: any = useMemo(() => {
    return new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting)
    })
  }, [])

  useEffect(() => {
    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref, observer])

  return { isVisible }
}
