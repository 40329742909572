/* eslint-disable */
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store"
import {
  setTitle,
  setBackUrl
} from "../../../Components/molecules/Navbar/slice"
import { Button } from "../../../Components/atom/button"
import { TextInput } from "../../../Components/atom/input"
import CustomAccordion from "../../../Components/atom/accordion"
import { Checkbox } from "../../../Components/atom/checkbox/index"
import {
  useCreateRolesMutation,
  useDetailRolesQuery,
  usePermissionsListQuery,
  useUpdateRolesMutation,
  useDeleteRolesMutation
} from "../../../services/roles"
import { useGetUserListQuery } from "../../../services/users"
import { LoadingCircle } from "../../../Components/atom/Loading/loadingCircle"
import Modal from "../../../Components/atom/modal"
import {
  checkPermission,
  PERMISSION_LIST
} from "../../../utils/checkPermission"
import { DeleteIcon, EditIcon } from "../../../Assets"
import DeleteModal from "../../../Components/molecules/Modal/DeleteModal"
import { CheckIcon, CloseSVGIcon } from "../../../Assets"
import { useTranslation } from "react-i18next"
import {
  errorMessage,
  showErrorMessage,
  showSuccessMessage
} from "../../../utils/showMessage"
// import { setName, setRole, setEmail, addUser, deleteUser } from "../slice"

interface payloadData {
  name: string
  permissions: string[]
  description: string
}

const currentUsername = localStorage.getItem("username") || ""

const RolesForm: React.FC = () => {
  /* eslint-disable */
  const { t } = useTranslation()
  const { id } = useParams()
  const intId = parseInt(id || "")
  const dispatch = useAppDispatch()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [cancelConfirm, setCancelConfirm] = useState(false)
  const { data: roleData, isFetching } = useDetailRolesQuery(
    { id: intId },
    {
      skip: !intId,
      refetchOnMountOrArgChange: true
    }
  )
  const { data: permissionList } = usePermissionsListQuery({
    refetchOnMountOrArgChange: true
  })

  const { data: userData } = useGetUserListQuery({
    pageIndex: 0,
    limit: 1,
    sortBy: "id",
    direction: "asc",
    search: currentUsername
  })

  const [editRole, { isLoading }] = useUpdateRolesMutation()
  const [createRole, { isLoading: isLoadingCreate }] = useCreateRolesMutation()
  const [deleteRole, { isLoading: DeleteRoleLoading }] =
    useDeleteRolesMutation()
  const navigate = useNavigate()
  const [formData, setFormData] = useState<payloadData>({
    name: "",
    permissions: [],
    description: ""
  })
  const [error, setError] = useState<boolean>(false)
  React.useEffect(() => {
    if (id) {
      dispatch(
        setTitle(isEditing ? t("navbar.edit-role") : t("navbar.detail-role"))
      )
    } else {
      dispatch(setTitle(t("navbar.add-role")))
      setIsEditing(true)
    }
    dispatch(setBackUrl("/role"))
  }, [isEditing])

  const permissionData: any = roleData && roleData?.permission
  const arrayPermission = permissionData?.split(",")
  useEffect(() => {
    if (roleData) {
      setFormData({
        ...formData,
        description: roleData?.description,
        name: roleData?.name,
        permissions: arrayPermission
      })
    }
  }, [roleData, permissionList])

  const onSave = async (): Promise<void> => {
    if (formData.name === "") {
      setError(true)
    } else {
      if (roleData) {
        await editRole({
          name: formData.name,
          description: formData.description,
          id: intId,
          permissions: formData.permissions.toString()
        })
          .unwrap()
          .then((res) => {
            setError(false)
            showSuccessMessage(t("toast.success-update-role"))
            setTimeout(() => {
              setFormData({
                ...formData,
                description: "",
                name: "",
                permissions: []
              })
              if (roleData?.name === userData[0]?.role) {
                localStorage.setItem(
                  "roles",
                  JSON.stringify(res.data.permissions.split(","))
                )
              }
              navigate("/role")
            }, 2000)
          })
          .catch((err: any) => {
            if (
              err.data?.message.includes("Role has been Exist") ||
              err.data?.message.includes("Permission is Invalid") ||
              err.data?.message.includes("Permission is Required")
            ) {
              errorMessage(err, null)
            } else {
              showErrorMessage(t("toast.error-invalid-form"))
            }
          })
      } else if (!roleData) {
        await createRole({
          name: formData.name,
          description: formData.description,
          permissions: formData.permissions.toString()
        })
          .unwrap()
          .then(() => {
            setError(false)
            showSuccessMessage(t("toast.success-add-role"))
            setTimeout(() => {
              setFormData({
                ...formData,
                description: "",
                name: "",
                permissions: []
              })
              navigate("/role")
            }, 2000)
          })
          .catch((err: any) => {
            if (
              err.data?.message.includes("Role has been Exist") ||
              err.data?.message.includes("Permission is Invalid") ||
              err.data?.message.includes("Permission is Required")
            ) {
              errorMessage(err, null)
            } else {
              showErrorMessage(t("toast.error-invalid-form"))
            }
          })
      }
    }
  }

  const inputClassName = `${
    isEditing ? "bg-lightgray1" : "bg-white"
  } text-[14px] text-gray1 w-[736px]`

  const handleDelete = async (): Promise<void> => {
    try {
      const deleteData = await deleteRole({
        ids: [intId]
      }).unwrap()

      if (deleteData) {
        setOpenDeleteModal(false)
        navigate("/role")
      }
    } catch (err: any) {
      errorMessage(err, null)
    }
  }
  return (
    <>
      <DeleteModal
        title={t("role.menu.wiz.content.role") || ""}
        dataName="Peran ini "
        isLoading={DeleteRoleLoading}
        openDelete={openDeleteModal}
        toggle={() => {
          setOpenDeleteModal(false)
        }}
        onClick={() => {
          void handleDelete()
        }}
        onClose={() => {
          setOpenDeleteModal(false)
        }}
      />

      <div
        className="w-full rounded-xl p-[24px]"
        style={{ boxShadow: "0px 2px 16px rgba(138, 141, 143, 0.2)" }}
      >
        <div className="w-full flex justify-between items-center mb-4">
          <p className="text-[14px] font-bold">{t("role.role-name")}</p>
          <TextInput
            disabled={!isEditing}
            border="border-transparent"
            className={inputClassName}
            type="text"
            placeholder={t("role.role-name") || ""}
            value={formData.name}
            onChange={(e) => {
              setFormData({ ...formData, name: e.target.value })
              if (e.target.value.length > 1) {
                setError(false)
              }
            }}
            error={error}
            required
            errorText={"Nama role wajib diisi"}
          />
        </div>
        <div className="w-full flex justify-between items-center mb-4">
          <p className="text-[14px] font-bold">{t("role.role-description")}</p>
          <TextInput
            disabled={!isEditing}
            border="border-transparent"
            className={inputClassName}
            type="text"
            placeholder={isEditing ? t("role.role-description") || "" : "-"}
            value={formData.description}
            onChange={(e) => {
              setFormData({ ...formData, description: e.target.value })
            }}
          />
        </div>
      </div>
      <div className="pb-[150px]">
        <div className="font-semibold m-[25px]">{t("role.permissions")}</div>
        <div
          className="w-full rounded-xl px-[24px]"
          style={{ boxShadow: "0px 2px 16px rgba(138, 141, 143, 0.2)" }}
        >
          {roleData &&
            roleData?.permissionHiearachy?.map((data: any) => {
              return (
                <CustomAccordion
                  title={
                    <div className="flex items-center">
                      <img
                        src={
                          data &&
                          require("../../../Assets/images/" +
                            t(`role.${data?.category}`))
                        }
                        alt="gambar"
                      />
                      <h2 className="ml-4 font-semibold">
                        {data?.categoryLabel}
                      </h2>
                    </div>
                  }
                  children={
                    <div>
                      <div>
                        {data?.listContent?.map((dataSub: any) => {
                          return (
                            <div>
                              <h2 className="m-4 font-semibold">
                                {t(`role.${dataSub?.title}`)}
                              </h2>
                              <div className="flex">
                                {dataSub?.listDetail?.map((dataDetail: any) => {
                                  return (
                                    <div className="ml-4 mr-4">
                                      {isEditing ? (
                                        <Checkbox
                                          label={
                                            dataDetail?.permissionTitleLabel
                                          }
                                          defaultChecked={dataDetail?.checked}
                                          onChange={(e: any) => {
                                            if (e.target.checked === true) {
                                              formData.permissions.push(
                                                dataDetail?.permission
                                              )
                                            } else if (
                                              e.target.checked === false
                                            ) {
                                              let filter =
                                                formData.permissions.filter(
                                                  (e) =>
                                                    e !== dataDetail?.permission
                                                )
                                              setFormData({
                                                ...formData,
                                                permissions: filter
                                              })
                                            }
                                          }}
                                        />
                                      ) : (
                                        <div className="flex flex-row gap-1 mb-2">
                                          {dataDetail?.checked ? (
                                            <CheckIcon className="w-[21px] h-[21px]" />
                                          ) : (
                                            <CloseSVGIcon className="w-[21px] h-[21px]" />
                                          )}
                                          <p className="text-[14px]">
                                            {dataDetail?.permissionTitleLabel}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  }
                />
              )
            })}
          {!roleData &&
            permissionList?.data?.map((data: any) => {
              return (
                <CustomAccordion
                  title={
                    <div className="flex items-center">
                      <img
                        src={
                          data &&
                          require("../../../Assets/images/" +
                            t(`role.${data?.category}`))
                        }
                        alt="gambar"
                      />
                      <h2 className="ml-4 font-semibold">
                        {data?.categoryLabel}
                      </h2>
                    </div>
                  }
                  children={
                    <div>
                      <div>
                        {data?.listContent?.map((dataSub: any) => {
                          return (
                            <div>
                              <h2 className="m-4 font-semibold">
                                {t(`role.${dataSub?.title}`)}
                              </h2>
                              <div className="flex">
                                {dataSub?.listDetail?.map((dataDetail: any) => {
                                  return (
                                    <div className="ml-4 mr-4">
                                      <Checkbox
                                        disabled={!isEditing}
                                        label={dataDetail?.permissionTitleLabel}
                                        defaultChecked={dataDetail?.checked}
                                        onChange={(e: any) => {
                                          if (e.target.checked === true) {
                                            formData.permissions.push(
                                              dataDetail?.permission
                                            )
                                          } else if (
                                            e.target.checked === false
                                          ) {
                                            let filter =
                                              formData.permissions.filter(
                                                (e) =>
                                                  e !== dataDetail?.permission
                                              )
                                            setFormData({
                                              ...formData,
                                              permissions: filter
                                            })
                                          }
                                        }}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  }
                />
              )
            })}
        </div>
      </div>

      <div className="flex gap-3 justify-end pr-[32px] pl-[32px] left-0  border py-3 fixed bottom-0 w-full bg-white z-20">
        {isEditing ? (
          <>
            {checkPermission(PERMISSION_LIST.WIZ_ROLE_DELETE) && id && (
              <Button
                className="h-[45px] w-[45px] pl-3"
                outlined={true}
                onClick={() => {
                  setOpenDeleteModal(true)
                }}
              >
                <DeleteIcon />
              </Button>
            )}
            <Button
              onClick={() => {
                setCancelConfirm(true)
              }}
              outlined
            >
              {t("button.cancel")}
            </Button>
            {(checkPermission(PERMISSION_LIST.WIZ_ROLE_UPDATE) ||
              checkPermission(PERMISSION_LIST.WIZ_ROLE_CREATE)) && (
              <Button onClick={onSave}>
                {isLoading || isLoadingCreate ? (
                  <LoadingCircle />
                ) : (
                  t("button.submit")
                )}
              </Button>
            )}
            <Modal
              isOpen={cancelConfirm}
              toggle={() => {
                setCancelConfirm(false)
              }}
            >
              <div className="w-[380px] h-[160px] flex flex-col justify-between">
                <span className="flex flex-col gap-2">
                  <h1 className="font-bold text-xl text-body">
                    Apakah anda yakin untuk membatalkan aksi?
                  </h1>
                </span>
                <div className="flex w-full gap-4 h-[45px]">
                  <Button
                    className="w-[50%]"
                    outlined={true}
                    onClick={() => {
                      setCancelConfirm(false)
                    }}
                  >
                    {t("button.no") || ""}
                  </Button>
                  <Button
                    className="w-[50%]"
                    onClick={() => {
                      navigate("/role")
                    }}
                  >
                    {t("button.yes") || ""}
                  </Button>
                </div>
              </div>
            </Modal>
          </>
        ) : (
          <>
            {checkPermission(PERMISSION_LIST.WIZ_ROLE_DELETE) && (
              <Button
                className="h-[45px] w-[45px] pl-3"
                outlined={true}
                onClick={() => {
                  setOpenDeleteModal(true)
                }}
              >
                <DeleteIcon />
              </Button>
            )}

            {checkPermission(PERMISSION_LIST.WIZ_ROLE_UPDATE) && (
              <Button
                className="h-[45px] bg-skyblue border-transparent"
                onClick={() => {
                  setIsEditing(true)
                }}
              >
                {isFetching ? (
                  <LoadingCircle />
                ) : (
                  <div className="flex flex-row gap-2">
                    <EditIcon />
                    <p className="text-[14px] font-semibold text-primary">
                      {t("button.edit-role") || ""}
                    </p>
                  </div>
                )}
              </Button>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default RolesForm
