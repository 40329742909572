import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface IInitialState {
  memberListState: {
    page: number
    pageSize: number
    sortBy: string
    direction: string
    confirmSearch: string
    filterByAge: string
    filterByGender: string
    filterByStatus: string
    filterBySubmissionDate: string
    filterByUmkmType: string
    listMemberTotal: number
    umkmLead: string
  }
  searchByName?: string
  memberId?: string
  totalTransaction?: number
}

const initialState: IInitialState = {
  memberListState: {
    page: 0,
    pageSize: 10,
    sortBy: "id",
    direction: "asc",
    confirmSearch: "",
    filterByAge: "",
    filterByGender: "",
    filterByStatus: "",
    filterBySubmissionDate: "",
    filterByUmkmType: "",
    listMemberTotal: 0,
    umkmLead: ""
  },
  searchByName: "",
  memberId: "",
  totalTransaction: 0
}

const umkmMemberListSlice = createSlice({
  name: "umkmMemberList",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.memberListState.page = action.payload
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.memberListState.pageSize = action.payload
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.memberListState.sortBy = action.payload
    },
    setDirection: (state, action: PayloadAction<string>) => {
      state.memberListState.direction = action.payload
    },
    setConfirmSearch: (state, action: PayloadAction<string>) => {
      state.memberListState.confirmSearch = action.payload
    },
    setFilterByAge: (state, action: PayloadAction<string>) => {
      state.memberListState.filterByAge = action.payload
    },
    setFilterByGender: (state, action: PayloadAction<string>) => {
      state.memberListState.filterByGender = action.payload
    },
    setFilterByStatus: (state, action: PayloadAction<string>) => {
      state.memberListState.filterByStatus = action.payload
    },
    setFilterBySubmissionDate: (state, action: PayloadAction<string>) => {
      state.memberListState.filterBySubmissionDate = action.payload
    },
    setFilterByUmkmType: (state, action: PayloadAction<string>) => {
      state.memberListState.filterByUmkmType = action.payload
    },
    setListMemberTotal: (state, action: PayloadAction<number>) => {
      state.memberListState.listMemberTotal = action.payload
    },
    setFilterLeadMember: (state, action: PayloadAction<string>) => {
      state.memberListState.umkmLead = action.payload
    },
    resetState: (state) => {
      state.memberListState.sortBy = "id"
      state.memberListState.direction = "asc"
      state.memberListState.confirmSearch = ""
      state.memberListState.filterByAge = ""
      state.memberListState.filterByGender = ""
      state.memberListState.filterByStatus = ""
      state.memberListState.filterBySubmissionDate = ""
      state.memberListState.filterByUmkmType = ""
      state.memberListState.umkmLead = ""
    },
    setSearchByName: (state, action: PayloadAction<string>) => {
      state.searchByName = action.payload
    },
    setMemberId: (state, action: PayloadAction<string>) => {
      state.memberId = action.payload
    },
    setTotalTransaction: (state, action: PayloadAction<number>) => {
      state.totalTransaction = action.payload
    }
  }
})

export const {
  setPage,
  setPageSize,
  setSortBy,
  setDirection,
  setConfirmSearch,
  setFilterByAge,
  setFilterByGender,
  setFilterByStatus,
  setFilterBySubmissionDate,
  setFilterByUmkmType,
  setListMemberTotal,
  resetState,
  setSearchByName,
  setMemberId,
  setFilterLeadMember,
  setTotalTransaction
} = umkmMemberListSlice.actions
export default umkmMemberListSlice.reducer
