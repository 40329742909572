// import { Link } from "react-router-dom"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import React from "react"
import { MoreArrowIcon } from "../../../Assets/index"

interface IcustomAccordion {
  children: React.ReactNode
  title: React.ReactNode
}

const CustomAccordion: React.FC<IcustomAccordion> = ({ children, title }) => {
  return (
    <Accordion
      sx={{
        boxShadow: "unset !important",
        paddingBottom: "1em",
        paddingTop: "1em"
      }}
    >
      <AccordionSummary
        expandIcon={<MoreArrowIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {title}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingTop: "unset !important",
          paddingBottom: "unset !important"
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default CustomAccordion
