import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { customFetchBase } from "../interceptor"
import { API } from "../../utils/constants/url"
import type {
  IGetRoleList,
  ICreateRoles,
  IDeleteRole,
  IRolesWithId
} from "./types"

export const rolesApi = createApi({
  reducerPath: "rolesApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    listRoles: builder.query<any, IGetRoleList>({
      query: ({ pageIndex, limit, sortBy, direction, search }) => ({
        url: API.ROLE.LIST_ROLE,
        method: "GET",
        params: {
          pageIndex,
          limit,
          sortBy,
          direction,
          search
        }
      }),
      transformResponse: (res: any, meta, arg) => {
        const data = res.data.map((data: any, i: number) => ({
          ...{
            id: data.id,
            name: data.name,
            permission: data.permission,
            description: data.description
          }
        }))
        res = data
        return res
      }
    }),
    detailRoles: builder.query({
      query: ({ id }) => ({
        url: `${API.ROLE.DETAIL_ROLE}/${id}`,
        method: "GET"
      }),
      transformResponse: (res: any, meta, arg) => {
        const data = {
          id: res.data.id,
          name: res.data.name,
          permission: res.data.permissions,
          description: res.data.description,
          permissionHiearachy: res.data.permissionHiearachy
        }
        res = data
        return res
      }
    }),
    createRoles: builder.mutation<any, ICreateRoles>({
      query: (payload) => ({
        url: API.ROLE.CREATE_ROLE,
        method: "POST",
        body: payload
      })
    }),
    deleteRoles: builder.mutation<boolean, IDeleteRole>({
      query: ({ ids }) => ({
        url: `${API.ROLE.DELETE_ROLE}/${ids}`,
        method: "DELETE"
      })
    }),
    updateRoles: builder.mutation<any, IRolesWithId>({
      query: (payload) => ({
        url: API.ROLE.UPDATE_ROLE,
        method: "PUT",
        body: payload
      })
    }),
    permissionsList: builder.query({
      query: () => ({
        url: API.ROLE.LIST_PERMISSION_ROLE,
        method: "GET"
      })
    })
  })
})
export const {
  useListRolesQuery,
  useCreateRolesMutation,
  useDeleteRolesMutation,
  useDetailRolesQuery,
  useUpdateRolesMutation,
  usePermissionsListQuery
} = rolesApi
