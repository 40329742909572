import React from "react"
import { Button } from "../../../atom/button"
import { LoadingCircle } from "../../../atom/Loading/loadingCircle"
import Modal from "../../../atom/modal"

export interface ModalType {
  openDelete: boolean
  width?: string
  height?: string
  title?: string
  dataName?: string
  toggle: () => void
  onBackdropPress?: React.MouseEventHandler<HTMLDivElement>
  onClick: () => void
  isLoading?: boolean
  onClose?: () => void
}
const DeleteModal: React.FC<ModalType> = ({
  openDelete,
  toggle,
  title,
  dataName,
  onClick,
  isLoading,
  onClose
}) => {
  return (
    <Modal isOpen={openDelete} toggle={toggle} title={`Hapus ${title}?`}>
      <div className="w-[380px] h-[160px] flex flex-col justify-between">
        <span className="flex flex-col gap-2">
          <p className="text-[16px]">
            Apakah anda yakin untuk menghapus {dataName}?{" "}
          </p>
        </span>

        <div className="flex w-full gap-4 h-[45px]">
          <Button className="w-[50%]" onClick={onClose} outlined={true}>
            Batal
          </Button>
          <Button className="w-[50%]" onClick={onClick}>
            {isLoading ? <LoadingCircle /> : "Hapus"}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal
