import React from "react"
import { TextInput } from "../../atom/input"
import { Button } from "../../atom/button"
import { SearchIcon, AddIcon } from "../../../Assets"

interface IUserSearchBox {
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  setConfirmSearch: React.Dispatch<React.SetStateAction<string>>
  buttonTitle: string
  searchPlaceholder?: string
  onClickButton: () => void
  showButtonAdd: boolean
  className?: string
}

const SearchAndAddBox: React.FC<IUserSearchBox> = (props) => {
  const {
    value,
    buttonTitle,
    searchPlaceholder,
    onChange,
    setConfirmSearch,
    onClickButton,
    showButtonAdd,
    className
  } = props

  const handleSubmit = (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    e.preventDefault()
    setConfirmSearch(value)
  }

  return (
    <div
      className={`flex flex-row mb-5 gap-2 items-center justify-end ${className}`}
    >
      <form
        onSubmit={(e) => {
          handleSubmit(e)
        }}
      >
        <TextInput
          placeholder={searchPlaceholder}
          className={`
            text-[14px]
            text-gray1
            w-[262px]
            h-[45px]
            bg-lightgray1
          `}
          value={value}
          leftComponent={
            <div
              className="cursor-pointer"
              onClick={(e) => {
                handleSubmit(e)
              }}
            >
              <SearchIcon />
            </div>
          }
          onChange={onChange}
        />
      </form>
      {showButtonAdd && (
        <Button className="h-[45px]" onClick={onClickButton}>
          <div className="flex flex-row items-center gap-2">
            <AddIcon />
            <p className="text-[14px]">{buttonTitle}</p>
          </div>
        </Button>
      )}
    </div>
  )
}

export default SearchAndAddBox
