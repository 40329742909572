import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { API } from "../../utils/constants/url"
import { basicQuery } from "../interceptor"
import type { IGetLogin, IGetRefreshToken } from "./types"

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: basicQuery,
  endpoints: (builder) => ({
    login: builder.mutation<any, IGetLogin>({
      query: (payload) => ({
        url: API.AUTH.LOGIN,
        method: "POST",
        body: payload
      })
    })
  })
})

export const refreshTokenApi = createApi({
  reducerPath: "refreshTokenApi",
  baseQuery: basicQuery,
  endpoints: (builder) => ({
    refreshToken: builder.mutation<any, IGetRefreshToken>({
      query: (payload) => ({
        url: API.AUTH.REFRESH_TOKEN,
        method: "POST",
        body: payload
      })
    })
  })
})

export const logoutApi = createApi({
  reducerPath: "logoutApi",
  baseQuery: basicQuery,
  endpoints: (builder) => ({
    logout: builder.mutation<any, IGetRefreshToken>({
      query: (payload) => ({
        url: API.AUTH.LOGOUT,
        method: "POST",
        body: payload
      })
    })
  })
})

export const { useLoginMutation } = loginApi
export const { useLogoutMutation } = logoutApi
export const { useRefreshTokenMutation } = refreshTokenApi
