import moment from "moment"
import { unparse } from "papaparse"
import { useTranslation } from "react-i18next"
import imageCompression from "browser-image-compression"
import JSZip from "jszip"
import { showErrorMessage } from "./showMessage"
import "moment/locale/id"
moment.locale("id")

export const t = (value: string): string => {
  const { t } = useTranslation()

  return t(value || "")
}

export const formatDate = (date: string, format: string): string => {
  const formattedDate = moment(date).format(format)
  return !date ? "-" : formattedDate
}

export const truncateString = (string: string, maxLength: number): string => {
  const truncate = string.slice(0, maxLength)
  if (string.length > maxLength) {
    return `${truncate}...`
  } else {
    return string
  }
}

export const handleTime = (
  val: any,
  limit: number,
  setTime: React.SetStateAction<any>
): void => {
  const number = /[0-9]/

  !val.target.value
    ? setTime("00")
    : parseInt(val.target.value) > limit
    ? setTime(limit.toString())
    : number.test(val.target.value)
    ? setTime(val.target.value)
    : val.preventDefault()
}

export const setRupiah = (val: number): string => {
  return `Rp${new Intl.NumberFormat("id-ID", {
    style: "decimal"
  }).format(val)}`
}

export const sanitizeUrl = (url: string): string => {
  return url.replaceAll("&amp;", "&")
}

export const allArrayEqual = (arr: any): boolean => {
  return new Set(arr).size === 1
}

export const downloadCSV = (
  array: any,
  headers: any,
  quotes: boolean | boolean[],
  fileName: string
): void => {
  const csv = unparse(array, {
    header: false,
    quotes
  })

  const headerColumn = unparse({ fields: headers, data: [] }, { quotes: true })

  const combinedCSV = `${headerColumn}${csv}`

  const csvData = new Blob([combinedCSV], { type: "text/csv;charset=utf-8;" })

  const csvURL = window.URL.createObjectURL(csvData)

  const testLink = document.createElement("a")
  testLink.href = csvURL
  testLink.setAttribute("download", `${fileName}.csv`)
  testLink.click()
}

export const checkPhoneNumber: any = (number: string): string => {
  if (number.slice(0, 2).includes("08")) {
    return number.slice(1)
  } else if (number.slice(0, 2).includes("62")) {
    return number.slice(2)
  } else {
    return number
  }
}

export const toBase64 = async (file: any): Promise<string> => {
  try {
    const result = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result as string)
      }
      reader.onerror = (error) => {
        reject(error)
      }
    })

    const splitBase64 = result.split("base64,")[1]
    return splitBase64
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const compressImage = async (
  file: File,
  maxSize: number
): Promise<File> => {
  const options = {
    maxSizeMB: maxSize // Set your desired maximum file size in MB
  }
  const fileExt = file.name.split(".")
  const fileExtension = fileExt[fileExt.length - 1]

  try {
    // Compress the image
    const compressedBlob = await imageCompression(file, options)
    const compressedFile = new File(
      [compressedBlob],
      `image.${fileExtension}`,
      {
        type: compressedBlob.type
      }
    )

    // return the compressedFile
    return compressedFile

    // You can now upload or display the compressed image
  } catch (err) {
    console.error("Image compression error:", err)
    throw err
  }
}

export const toZip = async (file: File): Promise<FormData> => {
  const zip = new JSZip()

  zip.file("imageData.txt", file)

  try {
    const blob = await zip.generateAsync({ type: "blob" })

    const zipBlob = new Blob([blob], { type: "application/zip" })
    const zipFile = new File([zipBlob], "file.zip", {
      type: "application/zip"
    })

    // Change into multipart/form-data
    const formData = new FormData()
    formData.append("file", zipFile)

    return formData
  } catch (err: any) {
    showErrorMessage(err)
    throw err
  }
}
