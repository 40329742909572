import React from "react"
interface ICard {
  component: React.ReactNode
  width?: number | string
  padding?: number
  center?: boolean
}
const Card: React.FC<ICard> = ({ component, width = "1/2", padding = 24, center = false }: ICard) => {
   /* eslint-disable */
  return (
      <div
        className={`w-${String(width)}  rounded-xl p-[${padding}px] m-${center === true ? 'auto' : 5}`}
        style={{ boxShadow: "0px 2px 16px rgba(138, 141, 143, 0.2)" }}
      >
        <div className="mb-3">{component}</div>
      </div>
  )
}
export default Card
