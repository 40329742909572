import React, { useEffect, useState } from "react"
import Table from "../../Components/atom/table"
import type { SortingState } from "@tanstack/react-table"
import PaginationComponent from "../../Components/molecules/Pagination"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../store"
import { setTitle, setBackUrl } from "../../Components/molecules/Navbar/slice"
import { useDeleteRolesMutation, useListRolesQuery } from "../../services/roles"
import SearchAndAddBox from "../../Components/molecules/SearchAndAddBox"
import DeleteModal from "../../Components/molecules/Modal/DeleteModal"
import { useTranslation } from "react-i18next"
// import { checkPermission, PERMISSION_LIST } from "../../utils/checkPermission"
import { errorMessage } from "../../utils/showMessage"
const Roles: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [page, setPage] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(10)
  const [search, setSearch] = useState<string>("")
  const [confirmSearch, setConfirmSearch] = useState<string>("")
  const [sortBy, setSortBy] = useState<string>("id")
  const [direction, setDirection] = useState<string>("asc")

  const {
    data: roleList,
    isFetching,
    isError
  } = useListRolesQuery(
    {
      pageIndex: 0,
      limit: 999,
      sortBy,
      direction,
      search: confirmSearch
    },
    {
      refetchOnMountOrArgChange: true
    }
  )
  React.useEffect(() => {
    dispatch(setTitle(t("role.menu.wiz.content.role")))
    dispatch(setBackUrl(""))
  }, [])

  useEffect(() => {
    setPage(0)
  }, [pageSize, confirmSearch])

  const sliceData = (e: any[]): any[] => {
    if (e) {
      return e.slice(page * pageSize, (page + 1) * pageSize)
    } else {
      return []
    }
  }

  const handleSortModelChange = React.useCallback((sortModel: SortingState) => {
    if (sortModel.length) {
      setSortBy(sortModel[0].id)
      setDirection(sortModel[0].desc ? "desc" : "asc")
    }
  }, [])

  return (
    <div className="relative z-10">
      <SearchAndAddBox
        onClickButton={() => {
          navigate("/role/add")
        }}
        buttonTitle={t("button.add-role")}
        searchPlaceholder={t("role.search-data") || ""}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value)
        }}
        setConfirmSearch={setConfirmSearch}
        // showButtonAdd={checkPermission(PERMISSION_LIST.ROLE_CREATE)}
        showButtonAdd={true}
      />

      <Table
        rows={isError ? [] : sliceData(roleList)}
        columns={COLUMNS}
        loading={isFetching}
        error={isError}
        manualPagination={true}
        manualSorting={true}
        onSortModelChange={handleSortModelChange}
      />
      <section
        className={`
          ${
            pageSize === 10 || pageSize === 25
              ? "h-[100px]"
              : pageSize === 50
              ? "h-[125px]"
              : pageSize === 100
              ? "h-[150px]"
              : ""
          }
        `}
      />

      <PaginationComponent
        page={page}
        setPage={setPage}
        total={roleList ? roleList.length : 0}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </div>
  )
}

const ActionIcons: React.FC<any> = (props) => {
  const { info } = props
  const navigate = useNavigate()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [deleteUser, { isLoading }] = useDeleteRolesMutation()
  const { t } = useTranslation()
  const handleDelete = async (): Promise<void> => {
    try {
      const deleteData = await deleteUser({
        ids: [info]
      }).unwrap()

      if (deleteData) {
        setOpenDeleteModal(false)
        navigate(0)
      }
    } catch (err: any) {
      errorMessage(err, null)
    }
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <DeleteModal
        title={t("role.menu.content.user") || ""}
        dataName="Peran ini "
        isLoading={isLoading}
        openDelete={openDeleteModal}
        toggle={() => {
          setOpenDeleteModal(false)
        }}
        onClick={() => {
          void handleDelete()
        }}
        onClose={() => {
          setOpenDeleteModal(false)
        }}
      />
      <>
        {/* {checkPermission(PERMISSION_LIST.ROLE_DELETE) && (
          <DeleteIcon
            className="cursor-pointer"
            onClick={() => {
              setOpenDeleteModal(true)
            }}
          />
        )} */}
        <button
          className="h-[28px] bg-primary rounded-full px-4"
          onClick={() => {
            navigate(`/role/${info}`)
          }}
        >
          <p className={"text-white text-[12px]"}>{t("button.detail")}</p>
        </button>
      </>
    </div>
  )
}

const COLUMNS = [
  {
    header: () => {
      const { t } = useTranslation()

      return <span className="text-[14px]">{t("role.role-name")}</span>
    },
    accessorKey: "name",
    enableSorting: true,
    cell: (info: any) => (
      <p className="text-[14px] truncate w-[140px]">
        {info.getValue() && info.getValue() !== "" && info.getValue() !== null
          ? info.getValue()
          : "-"}
      </p>
    )
  },
  {
    header: () => {
      const { t } = useTranslation()

      return <span className="text-[14px]">{t("role.role-description")}</span>
    },
    accessorKey: "description",
    enableSorting: true,
    cell: (info: any) => (
      <p className="text-[14px]  truncate w-[140px]">
        {info.getValue() && info.getValue() !== "" && info.getValue() !== null
          ? info.getValue()
          : "-"}
      </p>
    )
  },
  {
    header: () => {
      const { t } = useTranslation()

      return <span className="text-[14px]">{t("role.action")}</span>
    },
    accessorKey: "id",
    enableSorting: false,
    cell: (info: any) => <ActionIcons info={info.getValue()} />
  }
]

export default Roles
