import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { customFetchBase } from "../interceptor"
import { API } from "../../utils/constants/url"
import type {
  IResponse,
  IGetWaitingList,
  IGetDetailUmkm,
  IGetMemberList,
  IGetHistoryApproval,
  IReasonResponse,
  IApproveUMKM,
  IGetImage
} from "./types"

export const umkmApi = createApi({
  reducerPath: "umkmApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getWaitingList: builder.query<IResponse, IGetWaitingList>({
      query: (params) => ({
        url: API.UMKM.LIST_WAITING_APPROVAL,
        method: "GET",
        params
      })
    }),
    getWaitingListCounter: builder.query<IResponse, any>({
      query: () => ({
        url: API.UMKM.GET_WAITING_APPROVAL_COUNTER,
        method: "GET"
      })
    }),
    getDetailUmkm: builder.query<IResponse, IGetDetailUmkm>({
      query: (payload) => ({
        url: API.UMKM.DETAIL_UMKM,
        method: "GET",
        params: payload
      })
    }),
    getMemberList: builder.query<IResponse, IGetMemberList>({
      query: (params) => ({
        url: API.UMKM.LIST_MEMBER,
        method: "GET",
        params
      })
    }),
    getApprovalHistory: builder.query<IResponse, IGetHistoryApproval>({
      query: (params) => ({
        url: API.UMKM.LIST_APPROVAL_HISTORY,
        method: "GET",
        params
      })
    }),
    getApprovalReason: builder.query<IReasonResponse[], string>({
      query: () => ({
        url: API.UMKM.LIST_REASON_UMKM,
        method: "GET"
      }),
      transformResponse: (res: any, meta, arg) => {
        const data = res.data.map((data: any, i: number) => ({
          ...data
        }))
        res = data
        return res
      }
    }),
    approveUMKM: builder.mutation<IResponse, IApproveUMKM>({
      query: (payload) => ({
        url: API.UMKM.APPROVE_UMKM,
        method: "POST",
        body: payload
      })
    }),
    getUMKMType: builder.query<IResponse, any>({
      query: () => ({
        url: API.UMKM.LIST_UMKM_TYPE,
        method: "GET"
      })
    }),
    getKTPImage: builder.query<IResponse, IGetImage>({
      keepUnusedDataFor: 60 * 60,
      query: (params) => ({
        url: API.UMKM.GET_KTP_IMAGE_UMKM,
        method: "GET",
        params
      })
    })
  })
})

export const {
  useGetWaitingListQuery,
  useLazyGetWaitingListQuery,
  useGetWaitingListCounterQuery,
  useGetDetailUmkmQuery,
  useGetMemberListQuery,
  useGetApprovalHistoryQuery,
  useApproveUMKMMutation,
  useGetApprovalReasonQuery,
  useGetUMKMTypeQuery,
  useGetKTPImageQuery
} = umkmApi
