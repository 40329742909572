import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { customFetchBase } from "../interceptor"
import { API } from "../../utils/constants/url"
import type {
  IResponse,
  IGetProductList,
  IUploadImage,
  IGetImage,
  ICreateProduct,
  IUpdateProduct,
  IStatus,
  IGetProductByID,
  IDelete
} from "./types"

export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getProductList: builder.query<IResponse, IGetProductList>({
      query: (params) => ({
        url: API.PRODUCT.LIST_PRODUCT,
        method: "GET",
        params
      })
    }),
    uploadImage: builder.mutation<any, IUploadImage>({
      query: (payload) => ({
        url: API.PRODUCT.UPLOAD_IMAGE_PRODUCT,
        method: "POST",
        body: payload
      })
    }),
    getImage: builder.query<IResponse, IGetImage>({
      keepUnusedDataFor: 60 * 60,
      query: (params) => ({
        url: API.PRODUCT.GET_IMAGE_PRODUCT,
        method: "GET",
        params
      })
    }),
    createProduct: builder.mutation<IResponse, ICreateProduct>({
      query: (payload) => ({
        url: API.PRODUCT.CREATE_PRODUCT,
        method: "POST",
        body: payload
      })
    }),
    updateProduct: builder.mutation<IResponse, IUpdateProduct>({
      query: (payload) => ({
        url: API.PRODUCT.UPDATE_PRODUCT,
        method: "PUT",
        body: payload
      })
    }),
    activateProduct: builder.mutation<IResponse, IStatus>({
      query: (payload) => ({
        url: API.PRODUCT.ACTIVATE_PRODUCT,
        method: "POST",
        body: payload
      })
    }),
    getProductById: builder.query<IResponse, IGetProductByID>({
      query: ({ id }) => ({
        url: `${API.PRODUCT.DETAIL_PRODUCT}/${id}`,
        method: "GET"
      })
    }),
    deleteProduct: builder.mutation<IResponse, IDelete>({
      query: ({ id }) => ({
        url: `${API.PRODUCT.DELETE_PRODUCT}/${id}`,
        method: "DELETE"
      })
    })
  })
})

export const {
  useGetProductListQuery,
  useUploadImageMutation,
  useGetImageQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useActivateProductMutation,
  useGetProductByIdQuery,
  useDeleteProductMutation
} = productApi
