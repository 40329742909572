import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { customFetchBase } from "../interceptor"
import { API } from "../../utils/constants/url"
import type {
  INotificationResponse,
  INotificationParams,
  ICountUnreadNotificationResponse,
  IReadNotificationPayload,
  ILatestNotificationPayload
} from "./types"

export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    listNotification: builder.mutation<
      INotificationResponse,
      INotificationParams
    >({
      query: ({ pageIndex, limit }) => ({
        url: API.NOTIFICATION.LIST_NOTIFICATION,
        method: "GET",
        params: {
          pageIndex,
          limit
        }
      })
    }),
    countUnreadNotification: builder.query<
      ICountUnreadNotificationResponse,
      any
    >({
      query: () => ({
        url: API.NOTIFICATION.COUNT_UNREAD_NOTIFICATION,
        method: "GET"
      })
    }),
    readNotification: builder.mutation<any, IReadNotificationPayload>({
      query: (payload) => ({
        url: API.NOTIFICATION.READ_NOTIFICATION,
        method: "PUT",
        body: payload
      })
    }),
    getLatestNotification: builder.query<any, ILatestNotificationPayload>({
      query: (params) => ({
        url: API.NOTIFICATION.GET_LATEST_NOTIFICATION,
        method: "GET",
        params
      })
    })
  })
})

export const {
  useListNotificationMutation,
  useCountUnreadNotificationQuery,
  useReadNotificationMutation,
  useGetLatestNotificationQuery
} = notificationApi
