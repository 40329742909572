import React from "react"
import { MetaLogo } from "../../../Assets"

interface ILogoCard {
  className?: string
}

export const LogoCard: React.FC<ILogoCard> = ({ className }) => {
  return (
    <div className="mt-5 pb-2 flex items-center justify-center gap-3">
      <p className="text-[12px] text-[#68788D]">Present by:</p>
      <MetaLogo />
    </div>
  )
}

export const LogoCard2: React.FC<ILogoCard> = ({ className }) => {
  return (
    <div className="w-full pb-4 flex flex-row items-center justify-center pr-4 gap-3">
      <p className="text-[12px] text-[#68788D]">Present by:</p>
      <MetaLogo />
    </div>
  )
}
