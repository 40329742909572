import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

interface IInitialValue {
  dataUniversalModal: {
    open: boolean
    type: string
    title: string
    message: string
    callback: () => void
  }
}

const initialState: IInitialValue = {
  dataUniversalModal: {
    open: false,
    type: "",
    title: "",
    message: "",
    callback: () => {}
  }
}

export const universalModalSlice = createSlice({
  name: "UniversalModalSlice",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<any>) => {
      state.dataUniversalModal.open = true
      state.dataUniversalModal.type = action.payload.type
      state.dataUniversalModal.title = action.payload.title
      state.dataUniversalModal.message = action.payload.message
      state.dataUniversalModal.callback = action.payload.callback
    },
    closeModal: (state, action: PayloadAction<any>) => {
      state.dataUniversalModal.open = false
      state.dataUniversalModal.type = action.payload.type
      state.dataUniversalModal.title = action.payload.title
      state.dataUniversalModal.message = action.payload.message
      state.dataUniversalModal.callback = action.payload.callback
    },
    toggleModal: (state) => {
      state.dataUniversalModal.open = !state.dataUniversalModal.open
    }
  }
})

export const { openModal, closeModal, toggleModal } =
  universalModalSlice.actions
export default universalModalSlice.reducer
