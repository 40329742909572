import { fetchBaseQuery } from "@reduxjs/toolkit/query"
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from "@reduxjs/toolkit/query"
import { Mutex } from "async-mutex"
// import { openToast } from "../Components/molecules/Toast/slice"
import { Buffer } from "buffer"
import { store } from "../store"
import type { RootState } from "../store"
import {
  setRefreshToken,
  setAccessToken,
  setRoles,
  setNeedPassAdjustment
} from "./auth/slice"
import getCredential from "../utils/getCredential"
import { API } from "../utils/constants/url"
import { openModal } from "../Components/molecules/Alert/UniversalAlert/slice"

const baseUrl = process.env.REACT_APP_API_URL_LIVE
const username = process.env.REACT_APP_API_USERNAME || ""
const password = process.env.REACT_APP_API_PASSWORD || ""
const base64 = Buffer.from(username + ":" + password).toString("base64")
const mutex = new Mutex()

export const basicQuery = fetchBaseQuery({
  baseUrl: baseUrl || "",
  prepareHeaders: (headers) => {
    headers.set("Authorization", "Basic " + base64)
    if (base64 !== undefined) {
      headers.set("Authorization", "Basic " + base64)
    }
    headers.set("Content-Type", "application/json")
    return headers
  }
})

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token: string = getCredential().accessToken
    if (token) {
      headers.set(
        "Authorization",
        "Bearer " + (getState() as RootState).authSlice.accessToken
      )
    }
    headers.set("Content-Type", "application/json")
    return headers
  }
})

const baseQueryFile = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token: string = getCredential().accessToken
    if (token) {
      headers.set(
        "Authorization",
        "Bearer " + (getState() as RootState).authSlice.accessToken
      )
    }
    return headers
  }
})

const baseQueryRefreshToken = fetchBaseQuery({
  baseUrl
})

export const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)
  if (result.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()

      try {
        const refreshToken: string = getCredential().refreshToken
        const refreshResult: any = await baseQueryRefreshToken(
          {
            credentials: "include",
            url: API.AUTH.REFRESH_TOKEN,
            headers: {
              Authorization: "Basic " + base64
            },
            body: {
              refreshToken
            },
            method: "POST"
          },
          api,
          extraOptions
        )
        if (refreshResult.data) {
          store.dispatch(setAccessToken(refreshResult.data?.data.accessToken))
          store.dispatch(setRefreshToken(refreshResult.data?.data.refreshToken))
          store.dispatch(setRoles(refreshResult.data?.data.roles))
          store.dispatch(
            setNeedPassAdjustment(refreshResult.data?.data.needPasswordChanged)
          )

          localStorage.setItem(
            "accessToken",
            refreshResult.data?.data.accessToken
          )
          localStorage.setItem(
            "refreshToken",
            refreshResult.data?.data.refreshToken
          )
          localStorage.setItem(
            "roles",
            JSON.stringify(refreshResult.data?.data.roles)
          )
          localStorage.setItem(
            "needPassAdjustment",
            JSON.stringify(refreshResult.data?.data.needPasswordChanged)
          )

          result = await baseQuery(args, api, extraOptions)
        } else {
          store.dispatch(setAccessToken(""))
          store.dispatch(setRefreshToken(""))
          store.dispatch(setRoles([]))
          store.dispatch(setNeedPassAdjustment(false))

          localStorage.removeItem("WYZTKN")
          localStorage.removeItem("accessToken")
          localStorage.removeItem("refreshToken")
          localStorage.removeItem("roles")
          localStorage.removeItem("username")
          localStorage.removeItem("id")
          localStorage.removeItem("needPassAdjustment")

          api.dispatch(
            openModal({
              type: "error",
              title: "Sesi anda telah berakhir",
              message: "Silahkan re-login kembali",
              callback: () => {
                window.location.assign("/login")
              }
            })
          )

          // api.dispatch(
          //   openToast({
          //     type: "error",
          //     title: "Token gagal di perbarui",
          //     message: "silahkan re login kembali"
          //   })
          // )
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

export const customFileFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()
  let result = await baseQueryFile(args, api, extraOptions)
  if (result.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()

      try {
        const refreshToken: string = getCredential().refreshToken
        const refreshResult: any = await baseQueryRefreshToken(
          {
            credentials: "include",
            url: API.AUTH.REFRESH_TOKEN,
            headers: {
              Authorization: "Basic " + base64
            },
            body: {
              refreshToken
            },
            method: "POST"
          },
          api,
          extraOptions
        )
        if (refreshResult.data) {
          store.dispatch(setAccessToken(refreshResult.data?.data.accessToken))
          store.dispatch(setRefreshToken(refreshResult.data?.data.refreshToken))
          store.dispatch(setRoles(refreshResult.data?.data.roles))
          store.dispatch(
            setNeedPassAdjustment(refreshResult.data?.data.needPasswordChanged)
          )

          localStorage.setItem(
            "accessToken",
            refreshResult.data?.data.accessToken
          )
          localStorage.setItem(
            "refreshToken",
            refreshResult.data?.data.refreshToken
          )
          localStorage.setItem(
            "roles",
            JSON.stringify(refreshResult.data?.data.roles)
          )
          localStorage.setItem(
            "needPassAdjustment",
            JSON.stringify(refreshResult.data?.data.needPasswordChanged)
          )

          result = await baseQueryFile(args, api, extraOptions)
        } else {
          store.dispatch(setAccessToken(""))
          store.dispatch(setRefreshToken(""))
          store.dispatch(setRoles([]))
          store.dispatch(setNeedPassAdjustment(false))

          localStorage.removeItem("WYZTKN")
          localStorage.removeItem("accessToken")
          localStorage.removeItem("refreshToken")
          localStorage.removeItem("roles")
          localStorage.removeItem("username")
          localStorage.removeItem("id")
          localStorage.removeItem("needPassAdjustment")
          // api.dispatch(
          //   openToast({
          //     type: "error",
          //     title: "Token gagal di perbarui",
          //     message: "silahkan re login kembali"
          //   })
          // )
          api.dispatch(
            openModal({
              type: "error",
              title: "Sesi anda telah berakhir",
              message: "Silahkan re-login kembali",
              callback: () => {
                window.location.assign("/login")
              }
            })
          )
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQueryFile(args, api, extraOptions)
    }
  }

  return result
}
