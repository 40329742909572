import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface IInitialState {
  id: number[]
  checkedAll: boolean
}

const initialState: IInitialState = {
  id: [],
  checkedAll: false
}

const masterProductSlice = createSlice({
  name: "masterProductSlice",
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<number>) => {
      state.id.push(action.payload)
    },
    removeId: (state, action: PayloadAction<number>) => {
      const index = state.id.indexOf(action.payload)
      state.id.splice(index, 1)
    },
    setCheckedAll: (state, action: PayloadAction<boolean>) => {
      state.checkedAll = action.payload
    },
    resetId: (state) => {
      state.id = []
    }
  }
})

export const { setId, removeId, setCheckedAll, resetId } =
  masterProductSlice.actions
export default masterProductSlice.reducer
