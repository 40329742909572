import { useState, useEffect } from "react"

export const useCheckConnection = (): boolean => {
  const [connectionStatus, setConnectionStatus] = useState<boolean>(
    navigator.onLine
  )

  useEffect(() => {
    const setOnline = (): void => {
      setConnectionStatus(true)
    }

    const setOffline = (): void => {
      setConnectionStatus(false)
    }

    window.addEventListener("online", setOnline)
    window.addEventListener("offline", setOffline)

    return () => {
      window.removeEventListener("online", setOnline)
      window.removeEventListener("offline", setOffline)
    }
  }, [])

  return connectionStatus
}
