/* eslint-disable */
export const upperCase = /[A-Z]/
export const lowerCase = /[a-z]/
export const space = /[ ]/
export const empty = /[]/
export const number = /[0-9]/
export const specialChar = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#"']/
export const regex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validateEmail = (email: string) => {
  return regex.test(email)
}

export const validateUsername = (value: string): boolean => {
  return number.test(value) && (!specialChar.test(value) || !space.test(value))
}

export const validatePassword = (value: string): boolean => {
  return upperCase.test(value) && lowerCase.test(value) && number.test(value)
}

export const validateProductCode = (value: string): boolean => {
  return upperCase.test(value) && number.test(value) && !specialChar.test(value)
}

export const validateProductName = (value: string): boolean => {
  return (
    (upperCase.test(value) ||
      lowerCase.test(value) ||
      number.test(value) ||
      space.test(value)) &&
    !specialChar.test(value)
  )
}

export const isAlphabet = (value: string): boolean => {
  return upperCase.test(value) || lowerCase.test(value)
}

export const isNumber = (value: string): boolean => {
  return (
    (number.test(value) &&
      !upperCase.test(value) &&
      !lowerCase.test(value) &&
      !specialChar.test(value) &&
      !space.test(value)) ||
    empty.test(value)
  )
}

export const validateNumber = (input: string | null | undefined): string => {
  if (input === null || input === undefined) {
    return '';
  }

  // Use regex to extract only numbers
  const extractedNumbers = input.replace(/[^0-9]/g, '');

  return extractedNumbers;
};

