import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { customFetchBase } from "../interceptor"
import { API } from "../../utils/constants/url"
import type {
  IResponse,
  IGetTransactionList,
  IGetTransactionDetailById,
  IReasonResponse,
  ICancelReasonResponse,
  IApproveTransactions,
  ICancelTransactions,
  IListCanvasser,
  IAssignTransactions,
  IDetailDelivery,
  IRelatedDelivery,
  IChangeEstimateDelivery
} from "./types"

export const transactionsApi = createApi({
  reducerPath: "transactionsApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getTransactionsList: builder.query<any, IGetTransactionList>({
      query: (params) => ({
        url: API.TRANSACTION.LIST_TRANSACTION,
        method: "GET",
        params
      }),
      transformResponse: (res: any) => {
        const data = res.data.map((item: any) => ({
          ...{
            id: item.id,
            transactionDate: item.transactionDate,
            completedDate: item.completedDate,
            totalItem: item.totalItem,
            userFullName: {
              userFullName: item.userFullName,
              currentLoanInstallment: item.currentLoanInstallment,
              transactionStatus: item.transactionStatus
            },
            selectedData: {
              id: item.id,
              transactionStatus: item.transactionStatus,
              userFullName: item.userFullName
            },
            totalAmount: item.totalAmount,
            transactionStatus: item.transactionStatus,
            userId: item.userId
          }
        }))
        data.total = res.total
        res = data
        return res
      }
    }),
    getTransactionsDetail: builder.query<IResponse, IGetTransactionDetailById>({
      query: ({ transactionNo }) => ({
        url: `${API.TRANSACTION.DETAIL_TRANSACTION}/${transactionNo}`,
        method: "GET"
      })
    }),
    getTransactionReason: builder.query<IReasonResponse[], string>({
      query: () => ({
        url: API.TRANSACTION.GET_REASON,
        method: "GET"
      }),
      transformResponse: (res: any, meta, arg) => {
        const data = res.data.map((data: any, i: number) => ({
          ...data
        }))
        res = data
        return res
      }
    }),
    getTransactionCancelReason: builder.query<ICancelReasonResponse[], string>({
      query: () => ({
        url: API.TRANSACTION.GET_CANCEL_REASON,
        method: "GET"
      }),
      transformResponse: (res: any, meta, arg) => {
        const data = res.data.map((data: any, i: number) => ({
          ...data
        }))
        res = data
        return res
      }
    }),
    approveTransactions: builder.mutation<IResponse, IApproveTransactions>({
      query: (payload) => ({
        url: API.TRANSACTION.APPROVE_TRANSACTION,
        method: "POST",
        body: payload
      })
    }),
    cancelTransactions: builder.mutation<IResponse, ICancelTransactions>({
      query: (payload) => ({
        url: API.TRANSACTION.CANCEL_TRANSACTION,
        method: "POST",
        body: payload
      })
    }),

    getListCanvasser: builder.query<IResponse, IListCanvasser>({
      query: (params) => ({
        url: API.TRANSACTION.LIST_CANVASSER,
        method: "GET",
        params
      })
    }),
    assignTransactions: builder.mutation<IResponse, IAssignTransactions>({
      query: (payload) => ({
        url: API.TRANSACTION.ASSIGN_CANVASSER,
        method: "POST",
        body: payload
      })
    }),
    changeEstimateDelivery: builder.mutation<
      IResponse,
      IChangeEstimateDelivery
    >({
      query: (payload) => ({
        url: API.TRANSACTION.UPDATE_ETD_TRANSACTION,
        method: "POST",
        body: payload
      })
    }),
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getDeliveryReason: builder.query<IResponse, void>({
      query: () => ({
        url: API.TRANSACTION.GET_DELIVERY_REASON,
        method: "GET"
      })
    }),
    getDetailDelivery: builder.query<IResponse, IDetailDelivery>({
      query: (params) => ({
        url: API.TRANSACTION.DETAIL_DELIVERY_TRANSACTION,
        method: "GET",
        params
      })
    }),
    getRelatedDelivery: builder.query<IResponse, IRelatedDelivery>({
      query: ({ transactionId }) => ({
        url: `${API.TRANSACTION.LIST_RELATED_TRANSACTION}/${transactionId}`,
        method: "GET"
      })
    })
  })
})

export const {
  useGetTransactionsListQuery,
  useGetTransactionsDetailQuery,
  useGetTransactionReasonQuery,
  useGetTransactionCancelReasonQuery,
  useApproveTransactionsMutation,
  useCancelTransactionsMutation,
  useAssignTransactionsMutation,
  useGetListCanvasserQuery,
  useChangeEstimateDeliveryMutation,
  useGetDeliveryReasonQuery,
  useGetDetailDeliveryQuery,
  useGetRelatedDeliveryQuery
} = transactionsApi
