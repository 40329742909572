import { store } from "../store"
import { t } from "i18next"
import { openToast } from "../Components/molecules/Toast/slice"

export interface IErrorMessage {
  error?: string
  status: number | string
  data: {
    timestamp: string
    path: any
    status: number
    error: string
    message: string
    requestId: any
  }
}

export const errorMessage = (
  response: IErrorMessage,
  page: string | null
): void => {
  const status = response.status || ""
  const message = response.data?.message || ""
  const error = response.data?.error || ""

  switch (status) {
    case 400:
      if (message === "User is Not Found") {
        showErrorMessage(t("toast.error-forgot-password"))
      } else if (message === "Wrong new password") {
        showErrorMessage(t("toast.error-reset-password"))
      } else if (message === "Email has been Exist") {
        showErrorMessage(t("toast.error-email-exist"))
      } else if (message === "Username has been Exist") {
        showErrorMessage(t("toast.error-username-exist"))
      } else if (message === "Data has been Exist") {
        showErrorMessage(t("toast.error-data-exist"))
      } else if (message === "Code has been Exist" && page === "Category") {
        showErrorMessage(t("toast.error-category-code-exist"))
      } else if (message === "Name has been Exist" && page === "Category") {
        showErrorMessage(t("toast.error-category-name-exist"))
      } else if (message === "Code has been Exist" && page === "Unit") {
        showErrorMessage(t("toast.error-unit-code-exist"))
      } else if (message === "Name has been Exist" && page === "Unit") {
        showErrorMessage(t("toast.error-unit-name-exist"))
      } else if (message === "Title has been Exist") {
        showErrorMessage(t("toast.error-title-exist"))
      } else if (message === "Role has been Exist") {
        showErrorMessage(t("toast.error-role-exist"))
      } else if (message === "Permission is Invalid") {
        showErrorMessage(t("toast.error-invalid-permission"))
      } else if (message === "Permission is Required") {
        showErrorMessage(t("toast.error-permission-required"))
      } else if (message === "Invalid Delivery Date") {
        showErrorMessage(t("toast.error-assign-tomorrow"))
      } else if (message === "Invalid Estimated Received Date") {
        showErrorMessage(t("toast.error-etd-time"))
      } else if (message === "Wrong Old Password!") {
        showErrorMessage(t("toast.error-invalid-old-password"))
      } else if (message === "Old Password is Required!") {
        showErrorMessage(t("toast.error-old-password-required"))
      } else if (message === "Can not Update Using Same Password") {
        showErrorMessage(t("toast.error-same-password"))
      } else if (message === "Invalid Email Format") {
        showErrorMessage(t("errorInput.email"))
      } else if (message === "Invalid Username Format") {
        showErrorMessage(t("errorInput.username"))
      } else if (message === "Invalid Password") {
        showErrorMessage(t("errorInput.password"))
      } else if (message === "Canvasser has been Exist") {
        showErrorMessage(t("toast.error-canvasser-has-been-exist"))
      } else if (message === "Estimated Received Date has been Exist") {
        showErrorMessage(t("toast.error-received-date-has-been-exist"))
      } else if (message === "Invalid Estimated Received Date Format") {
        showErrorMessage(t("toast.error-invalid-received-date-format"))
      } else if (message === "Postal Code is Required") {
        showErrorMessage(t("toast.error-postal-code-mandatory"))
      } else if (message === "Province is Required") {
        showErrorMessage(t("toast.error-province-mandatory"))
      } else if (message === "Regency City is Required") {
        showErrorMessage(t("toast.error-regency-city-mandatory"))
      } else if (message === "District is Required") {
        showErrorMessage(t("toast.error-district-mandatory"))
      } else if (message === "Sub District is Required") {
        showErrorMessage(t("toast.error-subDistrict-mandatory"))
      } else if (message === "Distributor has been Exist") {
        showErrorMessage(t("toast.error-distributor-exist"))
      } else if (page === "Leads") {
        showErrorMessage(t("toast.error-import-leads"))
      } else if (page === "Approve UMKM") {
        showErrorMessage(t("toast.error-approve-umkm"))
      } else if (page === "Reject UMKM") {
        showErrorMessage(t("toast.error-reject-umkm"))
      } else if (page === "Product") {
        showErrorMessage(t("toast.error-no-product-selected"))
      } else if (page === "Forgot Password") {
        showErrorMessage(t("toast.error-forgot-password"))
      } else {
        showErrorMessage(t("toast.error-unknown"))
      }
      break
    case 401:
      if (error === "WrongAuthenticationException") {
        showErrorMessage(t("toast.error-auth"))
      } else {
        showErrorMessage(t("toast.error-unknown"))
      }
      break
    case 403:
    case 404:
    case 409:
      if (message === "Email has been Exist") {
        showErrorMessage(t("toast.error-email-exist"))
      } else if (message === "Role has Dependency with another User") {
        showErrorMessage(t("toast.error-role-in-use"))
      } else {
        showErrorMessage(t("toast.error-unknown"))
      }
      break
    case 503:
    case 504:
      showErrorMessage(t("toast.error-processing-data"))
      break
    default:
      if (message === "Product Category has Dependency with another Product") {
        showErrorMessage(t("toast.error-category-use"))
      } else if (status === "FETCH_ERROR") {
        showErrorMessage(t("toast.error-processing-data"))
      } else {
        showErrorMessage(t("toast.error-unknown"))
      }
  }
}

export const showErrorMessage = (message: string): void => {
  store.dispatch(
    openToast({
      type: "error",
      title: t("toast.error") || "",
      message: message || "-"
    })
  )
}

export const showSuccessMessage = (message: string): void => {
  store.dispatch(
    openToast({
      type: "success",
      title: t("toast.success") || "",
      message: message || "-"
    })
  )
}
