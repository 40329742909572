import React, { useEffect, useState } from "react"
import {
  PersonPrimaryIcon,
  LockIcon,
  LogoutIcon,
  ArrowIcon
} from "../../../Assets/index"
import { Menu, Transition } from "@headlessui/react"
import { useAppDispatch, useAppSelector } from "../../../store"
import { useNavigate } from "react-router-dom"
import { useLogoutMutation } from "../../../services/auth"
import { useCountUnreadNotificationQuery } from "../../../services/notification"
import { LoadingCircle } from "../../atom/Loading/loadingCircle"
import { setBackUrl } from "./slice"
import getCredential from "../../../utils/getCredential"
import {
  setRefreshToken,
  setAccessToken,
  setRoles,
  setNeedPassAdjustment
} from "../../../services/auth/slice"
import { useGetProfileQuery } from "../../../services/users"
import { useDispatch } from "react-redux"
import { CancelModal } from "./CancelModal"
import { useTranslation } from "react-i18next"
import BellNotif from "./notification"
import Modal from "../../atom/modal"
import { Button } from "../../atom/button"
interface INavbar {
  open: boolean
  setOpen: (t: boolean) => void
}
export const Navbar: React.FC<INavbar> = (props) => {
  const { open, setOpen } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { title, backUrl, fullname } = useAppSelector((s) => s.navbarSlice)
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false)
  const interval = Number(process.env.NOTIFICATION_INTERVAL) || 300000

  const {
    data: dataProfile,
    isLoading: profileLoading,
    refetch
  } = useGetProfileQuery({}, { refetchOnMountOrArgChange: true })

  const { data: totalUnread, refetch: notificationRefetch } =
    useCountUnreadNotificationQuery(
      {},
      {
        pollingInterval: interval
      }
    )

  useEffect(() => {
    if (dataProfile) {
      localStorage.setItem(
        "roles",
        JSON.stringify(dataProfile?.data?.role?.permissions?.split(","))
      )
    }
  }, [dataProfile])
  useEffect(() => {
    void refetch()
  }, [fullname])

  useEffect(() => {
    void notificationRefetch()
  }, [title])

  useEffect(() => {
    if (dataProfile) {
      localStorage.setItem("id", dataProfile.data.id)
    }
  }, [dataProfile, profileLoading])

  const titleWithBackIcon =
    window.location.pathname.includes("/users") ||
    window.location.pathname.includes("/waiting-approval") ||
    window.location.pathname.includes("/umkm-list-member") ||
    window.location.pathname.includes("/approval-history") ||
    window.location.pathname.includes("/master-product") ||
    window.location.pathname.includes("/orders-and-transactions") ||
    window.location.pathname.includes("/role") ||
    window.location.pathname.includes("/kelola-dashboard") ||
    window.location.pathname.includes("/")

  const formWithBackIcon =
    window.location.pathname.includes("/users") ||
    window.location.pathname.includes("/master-product") ||
    window.location.pathname.includes("/role") ||
    window.location.pathname.includes("/kelola-dashboard") ||
    window.location.pathname.includes("/")

  return (
    <>
      {formWithBackIcon && (
        <CancelModal
          openCancelModal={openCancelModal}
          setOpenCancelModal={setOpenCancelModal}
          title={t("navbar.confirm-cancel") || ""}
          backUrl={backUrl || ""}
        />
      )}

      <div
        className={`${
          open ? "lg:pl-[300px]" : "lg:pl-[100px]"
        } pl-[32px] w-full  h-[72px] border  pr-[32px] flex items-center justify-between fixed bg-white z-20`}
      >
        <svg
          onClick={() => {
            setOpen(!open)
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 cursor-pointer  lg:hidden visible"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>

        <div className="flex flex-row gap-2 items-center">
          {backUrl && titleWithBackIcon && (
            <ArrowIcon
              className="cursor-pointer"
              onClick={() => {
                if (formWithBackIcon) {
                  setOpenCancelModal(true)
                } else {
                  dispatch(setBackUrl(""))
                  navigate(backUrl)
                }
              }}
            />
          )}
          <h1 className="text-xl font-semibold">{title}</h1>
        </div>

        <div className="flex gap-[22px] items-center">
          <BellNotif totalUnread={totalUnread} />
          <div className="flex flex-row items-center gap-2">
            <ProfilePicture />
            <div className="flex flex-col">
              <p className="text-[14px] font-semibold">
                {dataProfile ? dataProfile.data.fullName : ""}
              </p>
              <p className="text-[12px] text-[#68788D]">
                {dataProfile ? dataProfile.data.role.name : ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const ProfilePicture: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [logout, { isLoading }] = useLogoutMutation()
  const [openLogoutModal, setOpenLogoutModal] = useState<boolean>(false)

  const actionLogout = (): void => {
    dispatch(setAccessToken(""))
    dispatch(setRefreshToken(""))
    dispatch(setRoles([]))
    dispatch(setNeedPassAdjustment(false))
    localStorage.removeItem("WYZTKN")
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("roles")
    localStorage.removeItem("username")
    localStorage.removeItem("id")
    localStorage.removeItem("needPassAdjustment")
    navigate("/login")
  }

  const onLogout = (): void => {
    logout({ refreshToken: getCredential().refreshToken })
      .unwrap()
      .then((d) => {
        actionLogout()
      })
      .catch(() => {
        actionLogout()
      })
  }
  return (
    <>
      <Menu as="div" className="relative inline-block text-left z-[999]">
        <Menu.Button className={"h-[56px]"}>
          <div className="w-[42px] h-[42px] rounded-full bg-skyblue flex justify-center items-center cursor-pointer">
            {/* <img src={PersonPrimaryIcon} alt="Profile" className="w-6" /> */}
            <PersonPrimaryIcon className="" />
          </div>
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={
              "bg-[#FFFF] fixed md:absolute lg:-right-6 -right-0 mt-3 lg:-translate-x-0 w-80 -translate-x-0  lg:w-[220px]  divide-y  rounded-md  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            }
          >
            <div
              className="flex space-x-4 p-4 cursor-pointer  hover:bg-[#F5F7F9] items-center"
              onClick={() => {
                navigate("/change-password")
                close()
              }}
            >
              <LockIcon />
              <h1 className="text-[16px] font-semi-bold">Ubah Password</h1>
            </div>
            <div
              className="flex space-x-4 p-4 ml-1 cursor-pointer  hover:bg-[#F5F7F9] items-center"
              onClick={() => {
                setOpenLogoutModal(true)
              }}
            >
              <LogoutIcon />
              <h1 className="text-[16px] font-semi-bold">Logout</h1>
              {isLoading && <LoadingCircle />}
            </div>
          </Menu.Items>
        </Transition>
        {openLogoutModal && (
          <Modal
            isOpen={openLogoutModal}
            toggle={() => {
              setOpenLogoutModal(false)
            }}
            title={"Logout Akun ?"}
          >
            <div className="w-[380px] h-[60px] flex flex-col justify-end">
              <div className="flex w-full gap-4 h-[45px]">
                <Button
                  className="w-[50%]"
                  outlined={true}
                  onClick={() => {
                    setOpenLogoutModal(false)
                  }}
                >
                  Batal
                </Button>
                <Button className="w-[50%]" onClick={onLogout}>
                  {isLoading ? <LoadingCircle /> : "Logout"}
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </Menu>
    </>
  )
}
