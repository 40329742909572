import React, { forwardRef } from "react"

export const Checkbox = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    error?: boolean
    errorText?: string
    label?: string
    border?: string
    type?: string
  }
>(({ error, errorText, label, border, type, ...rest }, ref) => (
  <>
    <div>
      <div>
        <div className="flex">
          <input
            type={"checkbox"}
            {...rest}
            className={`
            ${
              type === "alternate"
                ? `
              w-[24px]
              h-[24px]
              text-cyan1
              border-2
              border-gray4
              rounded-lg
              `
                : type === "primary-lg"
                ? `
              w-[24px]
              h-[24px]
              text-primary
              border-2
              border-gray4
              rounded-lg
              `
                : `
              mr-[10px]
              text-[14px]
              text-primary
              rounded-md
              `
            }
            cursor-pointer
            text-[14px]
            `}
            style={{
              boxShadow: "none"
            }}
            ref={ref}
          />
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            {label}
          </label>
        </div>
        {error ? (
          <p className="text-[12px] text-primary mt-1">{errorText}</p>
        ) : null}
      </div>
    </div>
  </>
))

Checkbox.displayName = "CheckBox"
