import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { API } from "../../utils/constants/url"
import { customFetchBase } from "../interceptor"
import type { ICreatedashboard, IdashboardWithId, IGetRoleList } from "./types"

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    listdashboard: builder.query<any, IGetRoleList>({
      query: ({ pageIndex, limit, sortBy, direction, search }) => ({
        url: API.DASHBOARD.LIST_DASHBOARD,
        method: "GET",
        params: {
          pageIndex,
          limit,
          sortBy,
          direction,
          search
        }
      }),
      transformResponse: (res: any, meta, arg) => {
        const data = res.data.map((data: any, i: number) => ({
          ...{
            id: data.id,
            title: data.title,
            description: data.description,
            priority: data.priority,
            roles: data.roles
          }
        }))
        res = data
        return res
      }
    }),
    detaildashboard: builder.query({
      query: ({ id }) => ({
        url: `${API.DASHBOARD.DETAIL_DASHBOARD}/${id}`,
        method: "GET"
      })
    }),
    listdashboardView: builder.query({
      query: () => ({
        url: API.DASHBOARD.VIEW_DASHBOARD,
        method: "GET"
      })
    }),
    createdashboard: builder.mutation<any, ICreatedashboard>({
      query: (payload) => ({
        url: API.DASHBOARD.CREATE_DASHBOARD,
        method: "POST",
        body: payload
      })
    }),
    deletedashboard: builder.mutation<boolean, any>({
      query: (id) => ({
        url: `${API.DASHBOARD.DELETE_DASHBOARD}/${id}`,
        method: "DELETE"
      })
    }),
    updatedashboard: builder.mutation<any, IdashboardWithId>({
      query: (payload) => ({
        url: API.DASHBOARD.UPDATE_DASHBOARD,
        method: "PUT",
        body: payload
      })
    })
  })
})
export const {
  useListdashboardQuery,
  useCreatedashboardMutation,
  useDeletedashboardMutation,
  useDetaildashboardQuery,
  useUpdatedashboardMutation,
  useListdashboardViewQuery
} = dashboardApi
