import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import getCredential from "../../utils/getCredential"
interface IAuth {
  accessToken: string
  refreshToken: string
  roles: string[]
  needPassAdjustment: boolean
}
const initialState: IAuth = {
  accessToken: getCredential().accessToken,
  refreshToken: getCredential().refreshToken,
  roles: getCredential().roles,
  needPassAdjustment:
    (localStorage.getItem("needPassAdjustment") ?? false) === "true"
}
const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload
    },
    setRoles: (state, action: PayloadAction<string[]>) => {
      state.roles = action.payload
    },
    setNeedPassAdjustment: (state, action: PayloadAction<boolean>) => {
      state.needPassAdjustment = action.payload
    }
  }
})

export const {
  setAccessToken,
  setRefreshToken,
  setRoles,
  setNeedPassAdjustment
} = authSlice.actions
export default authSlice.reducer
