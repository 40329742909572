import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface IInitialState {
  waitingState: {
    page: number
    pageSize: number
    sortBy: string
    direction: string
    confirmSearch: string
    filterByAge: string
    filterByGender: string
    filterByStatus: string
    filterBySubmissionDate: string
    filterByUmkmType: string
    waitingTotal: number
    umkmLead: string
  }
}

const initialState: IInitialState = {
  waitingState: {
    page: 0,
    pageSize: 10,
    sortBy: "id",
    direction: "asc",
    confirmSearch: "",
    filterByAge: "",
    filterByGender: "",
    filterByStatus: "",
    filterBySubmissionDate: "",
    filterByUmkmType: "",
    waitingTotal: 0,
    umkmLead: ""
  }
}

const umkmWaitingSlice = createSlice({
  name: "umkmWaiting",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.waitingState.page = action.payload
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.waitingState.pageSize = action.payload
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.waitingState.sortBy = action.payload
    },
    setDirection: (state, action: PayloadAction<string>) => {
      state.waitingState.direction = action.payload
    },
    setConfirmSearch: (state, action: PayloadAction<string>) => {
      state.waitingState.confirmSearch = action.payload
    },
    setFilterByAge: (state, action: PayloadAction<string>) => {
      state.waitingState.filterByAge = action.payload
    },
    setFilterByGender: (state, action: PayloadAction<string>) => {
      state.waitingState.filterByGender = action.payload
    },
    setFilterByStatus: (state, action: PayloadAction<string>) => {
      state.waitingState.filterByStatus = action.payload
    },
    setFilterBySubmissionDate: (state, action: PayloadAction<string>) => {
      state.waitingState.filterBySubmissionDate = action.payload
    },
    setFilterByUmkmType: (state, action: PayloadAction<string>) => {
      state.waitingState.filterByUmkmType = action.payload
    },
    setWaitingTotal: (state, action: PayloadAction<number>) => {
      state.waitingState.waitingTotal = action.payload
    },
    setFilterLead: (state, action: PayloadAction<string>) => {
      state.waitingState.umkmLead = action.payload
    },
    resetState: (state) => {
      state.waitingState.sortBy = "id"
      state.waitingState.direction = "asc"
      state.waitingState.confirmSearch = ""
      state.waitingState.filterByAge = ""
      state.waitingState.filterByGender = ""
      state.waitingState.filterByStatus = ""
      state.waitingState.filterBySubmissionDate = ""
      state.waitingState.filterByUmkmType = ""
      state.waitingState.umkmLead = ""
    }
  }
})

export const {
  setPage,
  setPageSize,
  setSortBy,
  setDirection,
  setConfirmSearch,
  setFilterByAge,
  setFilterByGender,
  setFilterByStatus,
  setFilterBySubmissionDate,
  setFilterByUmkmType,
  setWaitingTotal,
  setFilterLead,
  resetState
} = umkmWaitingSlice.actions
export default umkmWaitingSlice.reducer
