export const API = {
  AUTH: {
    LOGIN: "/oauth/authenticate/wiz",
    REFRESH_TOKEN: "/oauth/refreshtoken",
    LOGOUT: "/oauth/logout/wiz"
  },
  RENEW_PASSWORD: {
    RENEW: "/user/wiz/change-own-password/first-time"
  },
  FORGOT_PASSWORD: {
    SEND_EMAIL: "/oauth/wiz/forgot-password",
    RESET_PASSWORD: "/oauth/wiz/forgot-password/reset",
    CHECK_TOKEN: "/oauth/wiz/forgot-password/check"
  },
  CATEGORY: {
    LIST_CATEGORY: "/product-category/list",
    GET_CATEGORY: "/product-category/get",
    CREATE_CATEGORY: "/product-category/create",
    DELETE_CATEGORY: "/product-category/delete",
    UPDATE_CATEGORY: "/product-category/update"
  },
  DASHBOARD: {
    LIST_DASHBOARD: "/dashboard/list",
    DETAIL_DASHBOARD: "/dashboard/detail",
    VIEW_DASHBOARD: "/dashboard/view",
    CREATE_DASHBOARD: "/dashboard/create",
    DELETE_DASHBOARD: "/dashboard/delete",
    UPDATE_DASHBOARD: "/dashboard/update"
  },
  LEADS: {
    LIST_LEADS: "/leads/distributor/data",
    DETAIL_LEADS: "/leads/distributor/data/detail",
    CREATE_LEADS: "/leads/distributor/data/create",
    UPDATE_LEADS: "/leads/distributor/data/update",
    DELETE_LEADS: "/leads/distributor/data/delete",
    IMPORT_LEADS: "/leads/distributor/data/import",
    EXPORT_LEADS: "/leads/distributor/data/export"
  },
  NOTIFICATION: {
    LIST_NOTIFICATION: "/notification/distributor/list",
    COUNT_UNREAD_NOTIFICATION: "/notification/distributor/unread/counter",
    READ_NOTIFICATION: "/notification/read",
    GET_LATEST_NOTIFICATION: "/notification/distributor/counter/latest"
  },
  PRODUCT: {
    LIST_PRODUCT: "/product/list",
    UPLOAD_IMAGE_PRODUCT: "/file/product/image/str/upload",
    GET_IMAGE_PRODUCT: "/file/product/image/get",
    CREATE_PRODUCT: "/product/create",
    UPDATE_PRODUCT: "/product/update",
    ACTIVATE_PRODUCT: "/product/activation",
    DETAIL_PRODUCT: "/product/get",
    DELETE_PRODUCT: "/product/delete"
  },
  ROLE: {
    LIST_ROLE: "/role/wiz/list",
    DETAIL_ROLE: "/role/wiz/get",
    CREATE_ROLE: "/role/wiz/create",
    DELETE_ROLE: "/role/wiz/delete",
    UPDATE_ROLE: "/role/wiz/update",
    LIST_PERMISSION_ROLE: "/role/wiz/permission-hierarchy"
  },
  TRANSACTION: {
    LIST_TRANSACTION: "/transaction/dist/list",
    DETAIL_TRANSACTION: "/transaction/dist/list/detail",
    GET_REASON: "/transaction/master/approval/reason/list",
    GET_CANCEL_REASON: "/transaction/master/cancellation/reason/list",
    GET_DELIVERY_REASON: "/transaction/master/delivery/reason/list",
    APPROVE_TRANSACTION: "/transaction/dist/approval",
    CANCEL_TRANSACTION: "/transaction/distributor/cancel",
    LIST_CANVASSER: "/user/canvasser/delivery/list",
    ASSIGN_CANVASSER: "/transaction/distributor/delivery",
    UPDATE_ETD_TRANSACTION: "/transaction/distributor/update",
    DETAIL_DELIVERY_TRANSACTION: "/transaction/distributor/delivery/list",
    LIST_RELATED_TRANSACTION: "/transaction/distributor/related"
  },
  UMKM: {
    LIST_WAITING_APPROVAL: "/user/dist/list-umkm/waiting-approval",
    LIST_MEMBER: "/user/dist/list-umkm/member",
    LIST_APPROVAL_HISTORY: "/user/dist/list-umkm/history-approval",
    GET_WAITING_APPROVAL_COUNTER:
      "/user/dist/list-umkm/waiting-approval/counter",
    DETAIL_UMKM: "/user/dist/list-umkm/detail",
    LIST_REASON_UMKM: "/user/master/approval/reason/umkm-registration",
    APPROVE_UMKM: "/user/dist/approval/umkm-registration",
    LIST_UMKM_TYPE: "/user/master/distributor/umkm-type",
    GET_KTP_IMAGE_UMKM: "/file/registration/image/zip/get"
  },
  UNIT: {
    LIST_UNIT: "/product-unit/list",
    CREATE_UNIT: "/product-unit/create",
    UPDATE_UNIT: "/product-unit/update",
    DELETE_UNIT: "/product-unit/delete"
  },
  USER: {
    LIST_USER: "/user/wiz/list",
    LIST_ROLE_USER: "/role/wiz/list",
    DETAIL_USER: "/user/wiz/detail",
    CREATE_USER: "/user/wiz/create",
    UPDATE_USER: "/user/wiz/update",
    DELETE_USER: "/user/wiz/delete",
    RESET_PASSWORD: "/user/wiz/reset-password",
    UPDATE_PASSWORD: "/user/wiz/change-password",
    UPDATE_PROFILE_USER: "/user/distributor/profile/update",
    GET_PROFILE: "/user/distributor/profile/get",
    GET_LOCATION_USER: "/user/distributor/config/geolocation/data",
    UPDATE_LOCATION_USER: "/user/distributor/config/geolocation/update"
  },
  MULTI_DISTRIBUTOR: {
    LIST_DISTRIBUTOR: "/distributor/data/list",
    DETAIL_DISTRIBUTOR: "/distributor/data/get",
    GET_UMKM_TYPE: "/user/master/wiz/umkm-type",
    REGION_LIST: "/region/list",
    CREATE_DISTRIBUTOR: "/distributor/data/create",
    UPDATE_DISTRIBUTOR: "/distributor/data/update",
    DELETE_DISTRIBUTOR: "/distributor/data/delete",
    UPLOAD_LOGO_DISTRIBUTOR: "/file/distributor/logo/zip/upload",
    GET_LOGO_DISTRIBUTOR: "/file/distributor/logo/zip/get"
  }
}
