import React from "react"
import "./App.css"
import RoutePage from "./router"
import "./_i18n"
const Toast = React.lazy(
  async () => await import("./Components/molecules/Toast")
)
const UniversalAlert = React.lazy(
  async () => await import("./Components/molecules/Alert/UniversalAlert")
)

const App: React.FC = () => {
  return (
    <div>
      <RoutePage />
      <Toast />
      <UniversalAlert />
    </div>
  )
}

export default App
