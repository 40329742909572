import React from "react"

interface INotification {
  children: React.ReactNode
}

export const Notification: React.FC<INotification> = (props) => {
  const { children } = props
  return (
    <p className="text-[12px] bg-primary text-white font-medium px-2 rounded-full">
      {children}
    </p>
  )
}
